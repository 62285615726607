import React, { useState, useContext } from "react";
import "./Table.css";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import opacity from "react-element-popper/animations/opacity";
import transition from "react-element-popper/animations/transition";
import moment from "jalali-moment";
import InventoryContext, {
  InventoryProvider,
} from "../../context/InventoryContext";
import useIsMobile from "../useismobile/useIsMobile";

const Table = ({ parameter1, data }) => {
  console.log(data);

  const [filter, setFilter] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [values, setValues] = useState([]);
  const isMobile = useIsMobile();

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handleDateChange = (date) => {
    if (date.length > 1) {
      const formattedStartDate = moment(date[0].toDate())
        .locale("fa")
        .format("YYYY/MM/DD");
      const formattedEndDate = moment(date[1].toDate())
        .locale("fa")
        .format("YYYY/MM/DD");
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
    }
  };

  const clearFilters = () => {
    setFilter("");
    setSortOrder("");
    setStartDate("");
    setEndDate("");
    setValues([]);
  };

  // if (loadingStatus) {
  //   return <div>در حال بارگذاری...</div>;
  // }

  // const uniqueWarehouses = ["dd"];
  const uniqueWarehouses = [...new Set(data.map((item) => item.انبار))];
  const warehouses = uniqueWarehouses.map((warehouse, index) => (
    <option key={index} value={warehouse}>
      {warehouse}
    </option>
  ));

  const filteredData = data
    .filter((item) =>
      item["انبار"].toString().toLowerCase().includes(filter.toLowerCase())
    )
    .filter((item) => {
      if (startDate !== "" && endDate !== "") {
        return item["تاریخ"] >= startDate && item["تاریخ"] <= endDate;
      }
      return true;
    })
    .sort((a, b) => {
      if (sortOrder === "تعداد بالاترین") {
        return b["تعداد"] - a["تعداد"];
      } else if (sortOrder === "تعداد پایین‌ترین") {
        return a["تعداد"] - b["تعداد"];
      } else if (sortOrder === "جدید ترین") {
        return new Date(b["تاریخ"]) - new Date(a["تاریخ"]);
      } else if (sortOrder === "قدیمی ترین") {
        return new Date(a["تاریخ"]) - new Date(b["تاریخ"]);
      }
      return 0;
    });

  if (isMobile) {
    const tableEl = document.querySelector("table");
    const thEls = tableEl.querySelectorAll("thead th");
    const tdLabels = Array.from(thEls).map((el) => el.innerText);
    tableEl.querySelectorAll("tbody tr").forEach((tr) => {
      Array.from(tr.children).forEach((td, ndx) =>
        td.setAttribute("label", tdLabels[ndx])
      );
    });
  }

  return (
    <div
      className={
        parameter1 === "1"
          ? "table-container-v table-container"
          : "table-container"
      }>
      <div
        className={
          parameter1 === "1" ? "filter-select filter-select-v" : "filter-select"
        }>
        <div className="icon-filter-1 bi bi-funnel"></div>
        {parameter1 !== "1" && (
          <select id="filter" value={filter} onChange={handleChange}>
            <option value="" disabled hidden>
              نوع انبار:
            </option>
            <option value="">همه</option>
            {warehouses}
          </select>
        )}

        <select id="sortOrder" value={sortOrder} onChange={handleSortChange}>
          <option value="" disabled hidden>
            ترتیب نمایش:
          </option>
          <option value="تعداد بالاترین">تعداد بالاترین</option>
          <option value="تعداد پایین‌ترین">تعداد پایین‌ترین</option>
          <option value="جدید ترین">جدید ترین</option>
          <option value="قدیمی ترین">قدیمی ترین</option>
        </select>
        <DatePicker
          value={values}
          animations={[opacity(), transition({ from: 35, duration: 800 })]}
          onChange={handleDateChange}
          dateSeparator=" تا "
          range
          calendar={persian}
          locale={persian_fa}
          calendarPosition="bottom-right"
        />
        <button className="bi bi-arrow-clockwise" onClick={clearFilters}>
          حذف فیلترها
        </button>
      </div>

      <div className="sec-table-wrapper">
        <table className="sec-table">
          <thead>
            <tr>
              <th>ردیف</th>
              <th>نام</th>
              <th>انبار</th>
              <th>تعداد</th>
              <th>تاریخ</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item["نام"]}</td>
                <td>{item["انبار"]}</td>
                <td>{item["تعداد"]}</td>
                <td>{item["تاریخ"]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
