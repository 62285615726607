import React, { useState, useEffect } from "react";
import "./ViewPage.css";
import Table from "../../components/Table/Table";
import { ArrowSquareDown, FilterSquare, Calendar, Link } from "iconsax-react";
import { Calendar2, DocumentSketch, DocumentFilter } from "iconsax-react";
import SecondTab from "../../components/SecondTab/SecondTab";
import FirstTab from "../../components/FirstTab/FirstTab";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import API_URL from "../../components/config";

const WarehouseComponent = ({
  name,
  mojodi,
  date,
  time,
  onClick,
  isSelected,
}) => (
  <div className={`sec-cart ${isSelected ? "selected" : ""}`} onClick={onClick}>
    <div className="child-cart-top">
      <div className="child-cart-right">
        <div className="icon-sort-cart">
          <FilterSquare size="18" color="#FF8A65" />
        </div>
        <h6>انبار {name}</h6>
      </div>
      <div
        className={`child-cart-left ${
          isSelected ? "selected-background" : ""
        }`}>
        <p className="caption-lg">{mojodi}</p>
      </div>
    </div>
    <div className="child-cart-bottom">
      <div className="child-b-1">
        <Calendar size="20" color="#8392AB" />
        <p className="caption-md">آخرین ثبت</p>
      </div>
      <div className="child-b-2">
        <p className="caption-md">{date}</p>
        <p>-</p>
        <p className="caption-md">{time}</p>
      </div>
    </div>
  </div>
);

const ViewPage = ({ stock }) => {
  const navigate = useNavigate();
  const csrfToken = Cookies.get("csrfToken");
  const { id } = useParams();
  const [warehouses, setWarehouses] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [headerTitle, setHeaderTitle] = useState("");
  const [details, setDetails] = useState({});
  const [apiCalled, setApiCalled] = useState(false);

  const [chart, setChart] = useState({});

  const item = stock.find((item) => item.id === parseInt(id));

  useEffect(() => {
    if (item) {
      setHeaderTitle(`موجودی انبار : ${item.name}`);
    }
  }, [item]);
  const Logout = () => {
    const tokenString = localStorage.getItem("token");
    const tokenObject = JSON.parse(tokenString);
    const accessToken = tokenObject.access;
    const refreshToken = tokenObject.refresh;
    const formData = new URLSearchParams();
    formData.append("refresh_token", refreshToken);
    fetch(`${API_URL}/accounts/logout/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRFToken": csrfToken,
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData.toString(),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("logout");
        localStorage.removeItem("token");
        // localStorage.removeItem("access");
        // window.location.replace('http://127.0.0.1:8000/login');
        navigate("/login");
      });
  };
  useEffect(() => {
    const handleInvalidToken = async () => {
      const tokenString = localStorage.getItem("token");
      try {
        const tokenObject = JSON.parse(tokenString);
        const refreshToken = tokenObject.refresh;
        const formData = new URLSearchParams();
        formData.append("refresh", refreshToken);
        const response = await fetch(`${API_URL}/accounts/token/refresh/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-CSRFToken": csrfToken,
            // Add any other headers if needed
          },
          body: formData.toString(),
        });

        if (response.ok) {
          const data = await response.json();
          tokenObject.access = data.access;
          localStorage.setItem("token", JSON.stringify(tokenObject));
          console.log("Another API data:", data.access);
          fetchData();
        } else {
          console.error("Another API request failed", response.statusText);
          await Logout();
        }
      } catch (error) {
        console.error("An error occurred while calling another API", error);
      }
    };
    const fetchData = async () => {
      const tokenString = localStorage.getItem("token");
      try {
        const tokenObject = JSON.parse(tokenString);
        const accessToken = tokenObject.access;
        const response = await fetch(`${API_URL}/pannel/viewpage/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            // username: localStorage.getItem("user"),
            anbar: name,
          }),
        });

        const text = await response.text();
        try {
          console.log("saeed");
          const data = JSON.parse(text);
          setAdditionalData(data.Table || []);
          setDetails(data.details);
          setChart(data.chart);
          setWarehouses(
            data.cart.map((warehouse) => ({
              ...warehouse,
              date: warehouse.data.slice(0, 10),
              time: warehouse.data.slice(11),
            }))
          );
        } catch (e) {
          console.error("Failed to parse JSON:", text);
        }

        if (!response.ok) {
          if (response.status === 401) {
            await handleInvalidToken();
          } else if (response.status === 403) {
            Logout();
          }
          console.error("Submission failed", response.statusText);
          return;
        }
        setApiCalled(true);
      } catch (error) {
        console.error("An error occurred", error);
      }
    };

    fetchData();
  }, [item]);

  const handleWarehouseClick = (warehouse) => {
    if (selectedWarehouse && selectedWarehouse.id === warehouse.id) {
      return;
    }
    setSelectedWarehouse(warehouse);
    navigate(`/view/${warehouse.id}`);
    setApiCalled(false);
  };

  const [activeTab, setActiveTab] = useState("tab1");
  const handleTab1 = () => {
    setActiveTab("tab1");
  };
  const handleTab2 = () => {
    setActiveTab("tab2");
  };

  if (!item) {
    return <p>Item not found</p>;
  }

  const { name, mojodi } = item;

  return (
    <div className="main-viwe-page">
      {apiCalled ? (
        <div className="sec-view">
          <div className="main-p">
            <div className="main-header-p">
              <p className="title-header-p">{headerTitle}</p>
              <div className="notif-header-p">
                <p className="tex-notif-p">در حال حاضر</p>
              </div>
            </div>
            <ul className="main-firsSection-p">
              <li
                className={
                  activeTab === "tab1"
                    ? "active-p firstSection-button-p"
                    : "firstSection-button-p"
                }
                onClick={handleTab1}>
                <div className="firstButton-s">
                  <div className="icon-firstButton-p">
                    <DocumentSketch size="24" variant="Bold" />
                  </div>
                  <div className="title-firstButton-p">
                    <h6>مجموع کالا</h6>
                    <div className="title-firstButton-num-p">
                      <p>{mojodi}</p>
                      <p>عدد</p>
                    </div>
                  </div>
                </div>
              </li>
              <li
                className={
                  activeTab === "tab2"
                    ? "active-p firstSection-button-p"
                    : "firstSection-button-p"
                }
                onClick={handleTab2}>
                <div className="firstButton-s">
                  <div className="icon-firstButton-p">
                    <DocumentFilter size="24" variant="Bold" />
                  </div>
                  <div className="title-firstButton-p">
                    <h6>جزئیات انبار</h6>
                    <div className="title-firstButton-num-p">
                      <p>{details.type}</p>
                      <p>نوع محصول</p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div className="tabs-content">
              <div className={activeTab === "tab1" ? "tab" : "tab tab-hide"}>
                {activeTab === "tab1" && (
                  // <div></div>
                  <FirstTab
                    date={chart?.date || []}
                    inventory={chart?.data?.inventory || []}
                    In={chart?.data?.In || []}
                    out={chart?.data?.out || []}
                  />
                )}
              </div>
              <div className={activeTab === "tab2" ? "tab" : "tab tab-hide"}>
                {activeTab === "tab2" && (
                  <SecondTab
                    date={item.data.slice(0, 10)}
                    time={item.data.slice(11)}
                    inventory={details.inventory}
                    In={details.In}
                    out={details.out}
                    returned={details.returned}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="sec-stores">
            <div className="sec-info-stores">
              <p className="caption-lg">تعداد کل انبار های موجود</p>
              <div className="childs-stores">
                <div className="child-right-info-stores">
                  <h1>{warehouses.length}</h1>
                  <p className="caption-md">در حال حاضر</p>
                </div>
                <div className="child-left-info-stores">
                  <p className="caption-lg">ترتیب نمایش</p>
                  <ArrowSquareDown size="20" color="#292D32" variant="bold" />
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className="sec-scroll-stores">
              {warehouses.map((warehouse, index) => (
                <WarehouseComponent
                  key={index}
                  name={warehouse.name}
                  mojodi={warehouse.mojodi}
                  date={warehouse.date}
                  time={warehouse.time}
                  onClick={() => handleWarehouseClick(warehouse)}
                  isSelected={name === warehouse.name}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div id="loading" style={{ display: "contents" }}>
          <div className="wrapper" style={{ position: "relative" }}>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
          </div>
        </div>
      )}
      {apiCalled ? (
        <div className="table-s">
          <Table parameter1={"1"} data={additionalData} />
        </div>
      ) : null}
    </div>
  );
};

export default ViewPage;
