import React, { useState } from "react";
import "./SecondTab.css";
import {
  LoginCurve,
  LogoutCurve,
  ClipboardText,
  ArrowRotateRight,
  Calendar2,
} from "iconsax-react";

const SecondTab = ({ date, time, inventory, In, out, returned }) => {
  return (
    <div className="main-detailSection-p-s">
      <div className="detalSection-input-p">
        <div className="icon-input-p ">
          <LoginCurve size="18" color="#FFff" />
        </div>
        <h6>ورودی انبار</h6>
        <div className="week">
          <p>روزانه</p>
        </div>
        <div className="number">
          <h3>{In}</h3>
          <p>عدد</p>
        </div>
      </div>
      <div className="detalSection-output-p">
        <div className="icon-output-p">
          <LogoutCurve size="18" color="#FFff" />
        </div>
        <h6>خروجی انبار</h6>
        <div className="week">
          <p>روزانه</p>
        </div>
        <div className="number">
          <h3>{out}</h3>
          <p>عدد</p>
        </div>
      </div>
      <div className="detalSection-sum-p">
        <div className="icon-sum-p">
          <ClipboardText size="19" color="#ffff" />
        </div>
        <h6>اجناس باقی مانده</h6>
        <div className="week">
          <p>روزانه</p>
        </div>
        <div className="number">
          <h3>{inventory}</h3>
          <p>عدد</p>
        </div>
      </div>
      <div className="detalSection-return-p">
        <div className="icon-return-p">
          <ArrowRotateRight size="18" color="#FFff" />
        </div>
        <h6>مرجوع انبار</h6>
        <div className="week">
          <p>روزانه</p>
        </div>
        <div className="number">
          <h3>{returned}</h3>
          <p>عدد</p>
        </div>
      </div>
      <div className="detalSection-date-p">
        <div className="icon-date-p">
          <Calendar2 size="18" color="#ffff" />
        </div>
        <h6>آخرین ثبت</h6>
        <div className="week">
          <p>{time}</p>
        </div>
        <div>
          <h5>{date}</h5>
        </div>
      </div>
    </div>
  );
};

export default SecondTab;
