import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./profile.css";
import Cookies from "js-cookie";
import API_URL from "../../components/config";
import { useNavigate } from "react-router-dom";
function Profile() {
  const navigate = useNavigate();
  const csrfToken = Cookies.get("csrfToken");
  const [data, setData] = useState({});
  const [datakeys, setDatakeys] = useState([]);
  const [tokenVersion, setTokenVersion] = useState(0);
  // const Logout = () => {
  //   const tokenString = localStorage.getItem("token");
  //   const tokenObject = JSON.parse(tokenString);
  //   const accessToken = tokenObject.access;
  //   const refreshToken = tokenObject.refresh;
  //   const formData = new URLSearchParams();
  //   formData.append("refresh_token", refreshToken);
  //   fetch(`${API_URL}/accounts/logout/`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //       "X-CSRFToken": csrfToken,
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //     body: formData.toString(),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log("logout");
  //       localStorage.removeItem("token");
  //       // localStorage.removeItem("access");
  //       // window.location.replace('http://127.0.0.1:8000/login');
  //       navigate("/login");
  //     });
  // };
  // useEffect(() => {
  //   const handleInvalidToken = async () => {
  //     const tokenString = localStorage.getItem("token");
  //     try {
  //       const tokenObject = JSON.parse(tokenString);
  //       const refreshToken = tokenObject.refresh;
  //       const formData = new URLSearchParams();
  //       formData.append("refresh", refreshToken);
  //       const response = await fetch(`${API_URL}/accounts/token/refresh/`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //           "X-CSRFToken": csrfToken,
  //         },
  //         body: formData.toString(),
  //       });

  //       if (response.ok) {
  //         const data = await response.json();
  //         tokenObject.access = data.access;
  //         localStorage.setItem("token", JSON.stringify(tokenObject));
  //         console.log("Another API data:", data.access);
  //         setTokenVersion((prev) => prev + 1); // Trigger useEffect by updating state
  //       } else {
  //         console.error("Another API request failed", response.statusText);
  //         Logout();
  //       }
  //     } catch (error) {
  //       console.error("An error occurred while calling another API", error);
  //     }
  //   };

  //   const tokenString = localStorage.getItem("token");
  //   const tokenObject = JSON.parse(tokenString);
  //   const accessToken = tokenObject.access;
  //   const socket = new WebSocket(
  //     `ws://localhost:8000/ws/socket-server2/?token=${accessToken}`
  //   );

  //   socket.onopen = () => {
  //     console.log("WebSocket connected");
  //   };

  //   socket.onmessage = (event) => {
  //     const messageData = JSON.parse(event.data);
  //     console.log(messageData.data);
  //     if (messageData.data === "InvalidToken") {
  //       handleInvalidToken();
  //     } else if (messageData.data === "InvalidTokenuser") {
  //       Logout();
  //     }else {
  //       setDatakeys(Object.keys(messageData.data));
  //       Object.keys(messageData.data).forEach((element) => {
  //         messageData.data[element].sort(
  //           (a, b) => new Date(a.ok["تاریخ"]) - new Date(b.ok["تاریخ"])
  //         );
  //         console.log(messageData.data[element]);
  //       });
  //       setData(messageData.data);
  //     }
  //   };

  //   socket.onclose = () => {
  //     console.log("WebSocket disconnected");
  //   };

  //   return () => {
  //     socket.close();
  //   };
  // }, [tokenVersion]);

  return (
    <div className="container">
      <p>صفحه مربوط به پروفایل کاربری</p>
      <h1>در حال اماده سازی</h1>
      <div>
        <h1>Live Data</h1>

        {/* <div>
          {datakeys.map((itemkeys, indexkeys) => (
            <table>
              <thead>
                <h2>{itemkeys}</h2>
                <tr>
                  <th>ردیف</th>
                  <th>نام</th>
                  <th>id tag</th>
                  <th>user</th>
                  {Object.keys(data[itemkeys][0]["ok"]).map((item2, index) => (
                    <th>{item2}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data[itemkeys].map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item["نام"]}</td>
                    <td>{item["id_tag"]}</td>
                    <td>{item["user"]}</td>
                    {Object.keys(item["ok"]).map((item2, index) => (
                      <td>{item["ok"][item2]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          ))}
        </div>

        <div className="live">
          {datakeys.map((itemkeys, indexkeys) => (
            <div className="live-keys">
              {itemkeys}
              {data[itemkeys].map((item, index) => (
                <div className="live-data">
                  <div className="live-ok">
                    <p>نام</p>
                    <p>{item["نام"]}</p>
                  </div>
                  <div className="live-ok">
                    <p>id tag</p>
                    <p>{item["id_tag"]}</p>
                  </div>
                  <div className="live-ok">
                    <p>user</p>
                    <p>{item["user"]}</p>
                  </div>
                  {Object.keys(item["ok"]).map((item2, index) => (
                    <div className="live-ok">
                      <p>{item2}</p>
                      <p>{item["ok"][item2]}</p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
}

export default Profile;
