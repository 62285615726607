import React from "react";
import Lottie from "react-lottie";
import animationData from "../Shipwreck.json";

import "./NotFoundPage.css";

const NotFoundPage = () => {
  return (
    <div className="not-found-container">
      <Lottie
        options={{
          animationData: animationData,
        }}
      />
    </div>
  );
};

export default NotFoundPage;
