import "./App.css"; // وارد کردن فایل CSS
import jalaali from "jalaali-js";
import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import Modal from "react-modal";
import reader from "../assets/img/reader.svg";
import afsharImg from "../assets/img/afsharImg.svg";
import plusbutton from "../assets/img/plusbutton.svg";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
// import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
// import moment from 'jalali-moment';
import ReactApexChart from "react-apexcharts";
import {
  Dropdown,
  Link,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
  table,
} from "@nextui-org/react";
import transition from "react-element-popper/animations/transition";
import opacity from "react-element-popper/animations/opacity";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { differenceInDays, addDays } from "date-fns";
import moment from "jalali-moment";
import AnalogClock from "../components/AnalogClock/AnalogClock";
import API_URL from "../components/config.js";
import Select from "react-select";
// function WeekCalendar() {
//   const daysOfWeek = ['ش','ی', 'د', 'س', 'چ', 'پ', 'ج'];
//   /////////////////////0   1    2    3    4     5    6
//   /////////////////////5    6    7    8    9     10   11
//   const weekDays = [];
//   // const currentDate = moment();
//   // let startOfWeek = currentDate.clone().startOf('week').startOf('day');
//   //
//   // // بررسی اینکه آیا روز شنبه در ابتدای هفته است یا خیر
//   // if (startOfWeek.isoWeekday() !== 6) {
//   //   startOfWeek = currentDate.clone().startOf('week').add(1, 'week').startOf('day').isoWeekday(6);
//   // }
//     let now = moment().locale('fa').format('DD');
//     // let date1 = moment().locale('fa').format('DD');
//     // let date2 = moment().locale('fa').format('DD');
//     let weekDate = moment().locale('fa').format('d');
//     // let now = "8"
//     // let date1 = "8"
//     // let date2 = "8"
//     // let weekDate = "2"
//     weekDate++
//     for (let i = 0; i < 7; i++) {
//       if (i == weekDate) {
//         weekDays.push(
//           <div className="day-column1" key={i}>
//             <div className='column-background'>
//               <div className="day">{daysOfWeek[i]}</div>
//               <div className="date">{now}</div>
//             </div>
//           </div>
//         );
//       } else {
//         weekDays.push(
//           <div className="day-column" key={i}>
//             <div className="day">{daysOfWeek[i]}</div>
//             <div className="date">{now - (weekDate-i)}</div>
//           </div>
//         );
//       }
//     }
//
//   return (
//     <div className="week-calendar">
//       {weekDays}
//     </div>
//   );
// }

function WeekCalendar() {
  const daysOfWeek = ["ش", "ی", "د", "س", "چ", "پ", "ج"];
  const [weekDays, setWeekDays] = useState([]);

  useEffect(() => {
    const calculateWeekDays = () => {
      console.log("test");
      const weekDaysArray = [];
      const now = moment().locale("fa");

      // const weekDate = parseInt(now.format("d")) + 1; // Get current day of the week

      const day = parseInt(now.format("d"));
      const weekDate = day + 1 === 7 ? 0 : day + 1;

      const today = parseInt(now.format("DD")); // Get current day of the month
      console.log(weekDate);

      for (let i = 0; i < 7; i++) {
        if (i === weekDate) {
          weekDaysArray.push(
            <div className="day-column1" key={i}>
              <div className="column-background">
                <div className="day">{daysOfWeek[i]}</div>
                <div className="date">{today}</div>
              </div>
            </div>
          );
        } else {
          weekDaysArray.push(
            <div className="day-column" key={i}>
              <div className="day">{daysOfWeek[i]}</div>
              <div className="date">{today - (weekDate - i)}</div>
            </div>
          );
        }
        // else {
        //   weekDaysArray.push(
        //     <div className="day-column" key={i}>
        //       <div className="day">{daysOfWeek[i]}</div>
        //       <div className="date">{today - (weekDate - i)}</div>
        //     </div>
        //   );
        // }
      }
      setWeekDays(weekDaysArray);
    };

    calculateWeekDays();

    const interval = setInterval(calculateWeekDays, 60000); // 60000 milliseconds = 1 minute

    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(interval);
  }, []); // Run only once on component mount

  return <div className="week-calendar">{weekDays}</div>;
}

function DateDisplay() {
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      let jalaliDate = moment().locale("fa").format("jMMMM jYYYY");
      // jalaliDate++
      setCurrentDate(jalaliDate);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="date-display">
      <p>{currentDate}</p>
    </div>
  );
}

const StorePage = () => {
  const [listId, setListId] = useState([]);
  const [listValue, setListValue] = useState([]);
  const [sum, setSum] = useState(0);
  const [sum2, setSum2] = useState(0);
  const [sumDiscount, setSumDiscount] = useState(0);
  const [z, setZ] = useState(1);
  const [scrollTop, setScrollTop] = useState(0);
  const [readerid, setreaderid] = useState(0);
  const scrollSpeed = 2; // سرعت اسکرول (به پیکسل در ثانیه)
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [errorMessage3, setErrorMessage3] = useState("");
  const [products, setProducts] = useState([]);

  const csrfToken = Cookies.get("csrfToken");
  const [openModal, setOpenModal] = useState(false);
  const [inputValue, setInputValue] = useState(""); // State برای نگهداری مقدار ورودی
  const [isInputFilled, setIsInputFilled] = useState(false);

  const [openSecondModal, setOpenSecondModal] = useState(false);

  const [text, setText] = useState("");
  // const showDb_api = ["نام","فیمت","تخفیف","قیمت کل"]
  const [showDb_api, setshowDb_api] = useState([]);
  const [zorados, setzorados] = useState([]);
  const [currentTime, setCurrentTime] = useState("");
  const [cont, setCont] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [firstDate, setFirstDate] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [dates, setdates] = useState(null);
  const [seriescont, setseriescont] = useState(null);
  const [seriesindex, setseriesindex] = useState(null);
  const [options, setOptions] = useState([]);
  const [optionsinput, setOptionsinput] = useState([]);
  const [type, setType] = useState("");

  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        height: 300,
        type: "area",
        fontFamily: "ModamFaNumWeb",
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#32A4FC", "#00BA88"],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
      xaxis: {
        // max: 4,
        categories: [],
        labels: {
          show: true,
          rotate: 360,
          rotateAlways: true,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
            colors: [],
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          },
          offsetX: 0,
          offsetY: 0,
          format: undefined,
          formatter: undefined,
          datetimeUTC: true,
          datetimeFormatter: {
            year: "yyyy",
            month: "MMM 'yy",
            day: "dd MMM",
            hour: "HH:mm",
          },
        },
      },
    },
  });
  const {
    register,
    handleSubmit,
    setError,
    setvalue,
    formState: { errors, touchedFields, isValid },
    triggerValidation,
  } = useForm({
    mode: "onChange",
  });

  // const zorados = [
  //   { name: ["هودی", "کراپ", "شلوار"], code: "نام" },
  //   { name: ["پافر", "کلاه", "جاگر"], code: "نوع" },
  //   { name: ["مهر", "ابان"], code: "تاریخ" },
  //   { name: ["صبح", "شب"], code: "شیفت" },
  //   { name: ["002", "003", "004"], code: "ریدر" },
  // ];
  /*api test jwd start*/
  const fetchData = async (idR) => {
    const tokenString = localStorage.getItem("token");

    try {
      const tokenObject = JSON.parse(tokenString);
      const accessToken = tokenObject.access;

      const response = await fetch(`${API_URL}/api/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          // value: localStorage.getItem("user"),
          idR: idR,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setshowDb_api(data.id);
        console.log("data.ok", data.ok);
        console.log("data.cont", data.cont);
        console.log("data.date;", data.date);
        setzorados(data.ok); //////////////////////////////////////////////////////////

        const options = [];
        const options2 = [];

        data.ok.forEach((item) => {
          if (item.code === "input") {
            options2.push(item.name); // ذخیره مقادیر در options2
          } else {
            options.push({
              value: item.name.map((name) => ({
                value: item.code,
                label: name,
              })),
              label: item.code,
            });
          }
        });

        setOptions(options);
        setOptionsinput(options2);
        // console.log(data.ok.typeof)
        // setChartOptions((preChartOptions)=>{
        //   const updatedpreChartOptions = {...preChartOptions};
        //   updatedpreChartOptions.xaxis.categories = ["5","8","12","5454"];
        //   // updatedpreChartOptions.xaxis.convertedCatToNumeric = false;
        //   updatedpreChartOptions.series[0].data = [5,9,7];
        //   updatedpreChartOptions.series[1].data = [4,8,9];
        //   console.log(updatedpreChartOptions)
        //   return updatedpreChartOptions
        // })
        const sum_cont = data.cont.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        const sum_index = data.index.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        setCont([sum_cont, sum_index]);
        setChartData({
          series: [
            { data: data.cont, name: "کل محصولات" },
            { data: data.index, name: "نوع محصولات" },
          ],
          options: {
            ...chartData.options,
            xaxis: {
              ...chartData.options.xaxis,
              // categories: [1,3]
              categories: data.date,
            },
          },
        });
        setdates(data.date);
        setseriescont(data.cont);
        setseriesindex(data.index);
        setType(data.type);
        // const dates = getDatesFromChartData();
        // data.ok.forEach((value)=>{
        //   console.log(value)
        // })
        setOpenModal(false);
      } else if (response.status === 401) {
        await handleInvalidToken(idR);
      } else if (response.status === 404) {
        const data = await response.json();
        setErrorMessage(data.message);
        console.log(data.message);
        // console.log(response.json())
      } else {
        console.error("Submission failed", response.statusText);
        setErrorMessage("لطفا دوباره تلاش کنید");
      }
    } catch (error) {
      console.error("An error occurred", error);
      setErrorMessage("لطفا دوباره تلاش کنید");
    }
  };

  const handleInvalidToken = async (idR) => {
    const tokenString = localStorage.getItem("token");
    try {
      const tokenObject = JSON.parse(tokenString);
      const refreshToken = tokenObject.refresh;
      const formData = new URLSearchParams();
      formData.append("refresh", refreshToken);
      const response = await fetch(`${API_URL}/accounts/token/refresh/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRFToken": csrfToken,
          // Add any other headers if needed
        },
        body: formData.toString(),
      });

      if (response.ok) {
        const data = await response.json();
        tokenObject.access = data.access;
        localStorage.setItem("token", JSON.stringify(tokenObject));
        console.log("Another API data:", data.access);
        fetchData(idR);
      } else {
        console.error("Another API request failed", response.statusText);
        LoginForm();
      }
    } catch (error) {
      console.error("An error occurred while calling another API", error);
    }
  };

  const DataSend = async (idR, dataSend) => {
    let apiUrl = `${API_URL}/api/tagok/`;

    if (type === "ویرایش") {
      apiUrl = `${API_URL}/api/tagok/edit/`;
    }
    console.log(apiUrl);
    const tokenString = localStorage.getItem("token");

    try {
      const tokenObject = JSON.parse(tokenString);
      const accessToken = tokenObject.access;
      const refreshToken = tokenObject.refresh;
      const formData = new URLSearchParams();
      formData.append("refresh", refreshToken);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          // value: localStorage.getItem("user"),
          idR: idR,
          data: dataSend,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        // console.log(data.ok)
        // console.log(data.ok.typeof)
        // data.ok.forEach((value)=>{
        //   console.log(value)
        // })
        // setOpenModal(false);
        setOpenSecondModal(false);
        window.location.reload();
      } else if (response.status === 401) {
        try {
          const response2 = await fetch(`${API_URL}/accounts/token/refresh/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "X-CSRFToken": csrfToken,
              // Add any other headers if needed
            },
            body: formData.toString(),
          });

          if (response2.ok) {
            const data = await response2.json();
            tokenObject.access = data.access;
            localStorage.setItem("token", JSON.stringify(tokenObject));
            console.log("Another API data:", data.access);
            DataSend(readerid, dataSend);
          } else {
            console.error("Another API request failed", response2.statusText);
            await LoginForm();
          }
        } catch (error) {
          console.error("An error occurred while calling another API", error);
        }
      } else if (response.status === 404) {
        const data = await response.json();
        setErrorMessage2(data.message);
        console.log(data.message);
        // console.log(response.json())
      } else {
        console.error("Submission failed", response.statusText);
        setErrorMessage2("لطفا دوباره تلاش کنید");
      }
    } catch (error) {
      console.error("An error occurred", error);
      setErrorMessage2("لطفا دوباره تلاش کنید");
    }
  };

  /*api test jwd end*/

  useEffect(() => {
    let list1 = [];
    const url =  `wss://alohpanel.ir/ws/socket-server/`;
    const chatSocket = new WebSocket(url);

    chatSocket.onmessage = function (e) {
      let djangoData = JSON.parse(e.data);
      console.log("Data:", djangoData);
      console.log("readerid", readerid);
      console.log(djangoData.type === readerid);
      if (djangoData.type === readerid) {
        setListValue((prevListValue) => [...prevListValue, djangoData.value]); //list tag

        console.log("value:", djangoData.value);
        console.log("id:", djangoData.id);
        console.log("name:", djangoData.name);
        console.log("listValue:", listValue);
        console.log("listId:", listId);
        const tableBody = document.querySelector("table tbody");

        console.log(products);
        const existingProductIndex = list1.filter(
          (item) => item === djangoData.id
        ).length;
        console.log(existingProductIndex);
        if (existingProductIndex > 0) {
          let index = list1.indexOf(djangoData.id);
          console.log(index);
          setProducts((prevProducts) => {
            const updatedProducts = [...prevProducts];

            if (updatedProducts[index] !== undefined) {
              updatedProducts[index].quantity += 1;
              return updatedProducts;
            } else {
              // list1 = list1.filter(num => num !== djangoData.id);
              // setListValue(filteredList);
              return [
                ...prevProducts,
                {
                  id: djangoData.id,
                  value: djangoData.value,
                  name: djangoData.name,
                  quantity: 1, // تعداد اولیه یک است
                  // price: 12,
                  // discount: 2,
                },
              ];
            }
          });
        } else {
          list1.push(djangoData.id);
          setListId((prevListId) => [...prevListId, djangoData.id]); //list id
          setProducts((prevProducts) => [
            ...prevProducts,
            {
              id: djangoData.id,
              value: djangoData.value,
              name: djangoData.name,
              quantity: 1, // تعداد اولیه یک است
              // price: 12,
              // discount: 2,
            },
          ]);
        }
      }
    };

    const handleBeforeUnload = (event) => {
      chatSocket.send(
        JSON.stringify({
          message: "saeed", // برای این هست که اگه این پیام به بکند ارسال بشه لیست کامل پاک بشه و دوباره کانال وب سوکت وصل بشه
          idsocet: readerid,
          user: localStorage.getItem("user"),
        })
      );
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup function
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      chatSocket.close();
    };
  }, [readerid]);

  const decreaseQuantity = (quantityCell, product, row, tableBody) => {
    products.forEach(function (item, index) {
      if (item.id === product.id) {
        console.log("id", product.id);
        console.log("product", product);
        console.log("value", product.value);
        // const filteredList = listValue.filter(num => num !== product.value);
        setListValue((ListValue1) => {
          const filteredList = ListValue1.filter(
            (num) => num !== product.value
          );
          console.log("filteredList", filteredList);
          return filteredList;
        });
        console.log("listValue", listValue);
        if (item.quantity === 1) {
          // const filteredList1 = listId.filter(num => num !== product.id);
          setListId((ListId1) => {
            const filteredListId = ListId1.filter(
              (num) => num !== product.value
            );
            console.log("filteredListId", filteredListId);
            return filteredListId;
          });
          products.splice(index, 1);
          // const list = [...products];
          // console.log(list);
          // list.splice(index, 1);
          // console.log(list);
          row.innerHTML = "";
          tableBody.removeChild(row);

          // setProducts(list);
          console.log(products);
        } else {
          item.quantity -= 1;
          quantityCell.textContent = convertToPersianNumber(
            item.quantity.toLocaleString()
          );
          console.log("item", item);
        }
      }
    });
    console.log(quantityCell);
  };
  useEffect(() => {
    const updateRows = () => {
      let totalSum = 0;
      let totalSum2 = 0;
      let totalDiscount = 0;

      const tableBody = document.querySelector("table tbody");
      tableBody.innerHTML = ""; // پاک کردن ردیف‌های قبلی
      let contradif = 0;
      products.forEach((product) => {
        const row = document.createElement("tr");
        const radif = document.createElement("td");
        contradif++;
        radif.textContent = contradif.toLocaleString();
        row.appendChild(radif);
        product.name.forEach((saeed) => {
          const defalt = document.createElement("td");
          defalt.textContent = saeed.toLocaleString();
          row.appendChild(defalt);
        });
        const quantityCell = document.createElement("td");
        quantityCell.textContent = convertToPersianNumber(
          product.quantity.toLocaleString()
        );
        row.appendChild(quantityCell);
        const deleteCell = document.createElement("td");
        const deleteButton = document.createElement("button");
        deleteButton.textContent = "حذف";
        deleteButton.addEventListener("click", () =>
          decreaseQuantity(quantityCell, product, row, tableBody)
        );
        deleteCell.appendChild(deleteButton);
        row.appendChild(deleteCell);
        // const nameCell = document.createElement("td");
        // const quantityCell = document.createElement("td");
        // const priceCell = document.createElement("td");
        // const discountCell = document.createElement("td");
        // const totalCell = document.createElement("td");
        //
        // nameCell.textContent = product.name;
        // quantityCell.textContent = convertToPersianNumber(
        //   product.quantity.toLocaleString()
        // );
        // priceCell.textContent = convertToPersianNumber(
        //   product.price.toLocaleString()
        // );
        // discountCell.innerHTML =
        //   '<div class="div-discount">' +
        //   convertToPersianNumber(
        //     (
        //       product.quantity *
        //       (product.price - product.discount)
        //     ).toLocaleString()
        //   ) +
        //   "</div>";
        // totalCell.textContent = convertToPersianNumber(
        //   product.discount.toLocaleString()
        // );
        totalSum += product.quantity;
        // totalDiscount += product.discount;
        //
        // row.appendChild(nameCell);
        // row.appendChild(priceCell);
        // row.appendChild(quantityCell);
        // row.appendChild(totalCell);
        // row.appendChild(discountCell);
        //
        tableBody.appendChild(row);
      });

      // const qeimatElement = document.getElementById("qeimat");
      // const qeimatKolElement = document.getElementById("qeimat-kol");
      // const discountElement = document.getElementById("discount");
      //
      // const newSum = contradif;
      // const newSum2 = totalSum;
      // const newSumDiscount = totalDiscount;
      //
      // setSum(newSum);
      // setSum2(newSum2);
      // setSumDiscount(newSumDiscount);
      //
      // qeimatElement.innerHTML = convertToPersianNumber(newSum.toLocaleString());
      // qeimatKolElement.innerHTML =
      //   "تعداد کل محصولات : " + convertToPersianNumber(newSum2.toLocaleString());
      //
      // discountElement.innerHTML =
      //   convertToPersianNumber(
      //     ((newSumDiscount / newSum2) * 100).toLocaleString()
      //   ) + "%";
      //
      // const loaderPercentage =
      //   ((newSumDiscount / newSum2) * 100).toLocaleString() + "%";
      // const styleElement = document.createElement("style");
      // styleElement.innerHTML = `#loader::before {
      //   width: ${loaderPercentage};
      // }`;
      //
      // document.head.appendChild(styleElement);

      // if(contradif !== 0){

      // }
    };

    // هر بار که مقدار products به‌روزرسانی می‌شود، تابع updateRows را فراخوانی می‌کنیم
    updateRows();
  }, [products]);

  function convertToPersianNumber(number) {
    const persianNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    const englishNumbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

    let convertedNumber = "";

    for (let i = 0; i < number.length; i++) {
      const index = englishNumbers.indexOf(number[i]);
      if (index !== -1) {
        convertedNumber += persianNumbers[index];
      } else {
        convertedNumber += number[i];
      }
    }

    return convertedNumber;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  // const [currentTime, setCurrentTime] = useState(new Date());
  //
  // useEffect(() => {
  //   const intervalID = setInterval(() => tick(), 1000);
  //   return () => clearInterval(intervalID);
  // }, []);
  //
  // const tick = () => {
  //   setCurrentTime(new Date());
  // };
  // const gDate = currentTime;
  // const jDate = jalaali.toJalaali(
  //   gDate.getFullYear(),
  //   gDate.getMonth() + 1,
  //   gDate.getDate()
  // );
  // const jalaliDate = `${jDate.jy}/${jDate.jm}/${jDate.jd}`;
  // const currentTimeString = `${gDate.getHours()}:${gDate.getMinutes()}`;
  //
  // const days = [
  //   "یکشنبه",
  //   "دوشنبه",
  //   "سه‌شنبه",
  //   "چهارشنبه",
  //   "پنج‌شنبه",
  //   "جمعه",
  //   "شنبه",
  // ];
  // const today = new Date();
  // const dayName = days[today.getDay()];
  //////////////////////////////////////////////////////////////////////////////////////////////

  const refreshPage = async () => {
    console.log(1);
    window.location.reload();
  };

  const postToApi = () => {
    setOpenSecondModal(true);
    const jalaliDate = moment().locale("fa").format("jYYYY/jMM/jDD HH:mm:ss");
    // setCurrentTime(jalaliDate);
    console.log(jalaliDate);

    // const options = dataFromApi.map((item) => ({
    //   value: item.name.map((name) => ({ value: name, label: name })),
    //   label: item.code,
    // }));

    // setOptions(options);
    // if (zorados[zorados.length - 1].code === "تاریخ") {
    if (options.length === 0) {
      setOptions([
        {
          value: [{ value: "تاریخ", label: jalaliDate }],
          label: "تاریخ",
        },
      ]);
    } else if (options[options.length - 1].label === "تاریخ") {
      console.log("saeed");
      setOptions((prevzorados) => {
        const updatedprevzorados = [...prevzorados];
        updatedprevzorados[updatedprevzorados.length - 1].value = [
          { value: jalaliDate, label: jalaliDate },
        ];
        return updatedprevzorados;
      });
      // setzorados((prevzorados) => {
      //   const updatedprevzorados = [...prevzorados];
      //   updatedprevzorados[updatedprevzorados.length - 1].name = [jalaliDate];
      //   return updatedprevzorados;
      // });
    } else {
      setOptions((prevzorados) => [
        ...prevzorados,
        {
          value: [{ value: "تاریخ", label: jalaliDate }],
          label: "تاریخ",
        },
      ]);
      // setzorados((prevzorados) => [
      //   ...prevzorados,
      //   {
      //     name: [jalaliDate],
      //     code: "تاریخ",
      //   },
      // ]);
    }

    //   fetch('http://127.0.0.1:8000/api/', {
    //     method: 'POST',
    //     body: JSON.stringify({ value: listValue }),
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'X-CSRFToken': csrfToken
    //         // این باید با jwd درست بشه که هنوز درست نشده و کار نمیکنه
    //     }
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     console.log(listValue)
    //     refreshPage()
    //   })
  };
  const navigate = useNavigate();
  const LoginForm = () => {
    const tokenString = localStorage.getItem("token");
    const tokenObject = JSON.parse(tokenString);
    const accessToken = tokenObject.access;
    const refreshToken = tokenObject.refresh;
    const formData = new URLSearchParams();
    formData.append("refresh_token", refreshToken);
        fetch(`${API_URL}/accounts/logout/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'X-CSRFToken': csrfToken,
              "Authorization": `Bearer ${accessToken}`
          },
          body:formData.toString(),
      })
        .then(response => response.json())
        .then(data => {
          console.log('logout')
          localStorage.removeItem("token");
          // localStorage.removeItem("access");
          // window.location.replace('http://127.0.0.1:8000/login');
          navigate('/login')
        })
    };
  const setMobileTable = (selector) => {
    const tableEl = document.querySelector(selector);
    const thEls = tableEl.querySelectorAll("thead th");
    const tdLabels = Array.from(thEls).map((el) => el.innerText);
    tableEl.querySelectorAll("tbody tr").forEach((tr) => {
      Array.from(tr.children).forEach((td, ndx) =>
        td.setAttribute("label", tdLabels[ndx])
      );
    });
  };

  useEffect(() => {
    setMobileTable("table");
  }, [products]);
  // const readerData = new URLSearchParams();
  const onFormSubmit = async (data) => {
    // setOpenModal(false);
    console.log(data.readername);
    // readerData.append("id", data.readername);
    setreaderid(data.readername);
    fetchData(data.readername);
  };

  useEffect(() => {
    setOpenModal(true);
  }, []);

  const closeModal = () => {
    setOpenModal(false);
  };
  const closeModal2 = () => {
    setOpenSecondModal(false);
  };

  const [selectedOption, setSelectedOption] = useState("");
  const handleReportClick = (e) => {
    e.preventDefault();
    let saeed = selectedOption;
    if (!selectedOption) {
      saeed = zorados.find((item) => item.code === "ریدر").name[0];
      console.log(saeed);
    } else {
      console.log(saeed);
    }
  };

  const handleSelectChange1 = (event) => {
    setSelectedOption(event.target.value);
  };

  const showDb = ["ردیف", ...showDb_api, "تعداد", "عملیات"];
  const thElements = showDb.map((item, index) => {
    return <th key={index}>{item}</th>;
  });

  const [selectedOptions, setSelectedOptions] = useState({});

  const handleSubmit0 = (e) => {
    e.preventDefault();

    const newSelectedOptions = { ...selectedOptions };

    options.forEach((options) => {
      if (!newSelectedOptions[options.label] && options.value.length == 1) {
        console.log(options.value.length == 1);
        newSelectedOptions[options.label] = options.value[0].label;
      }
    });
    console.log("newSelectedOptions", Object.keys(newSelectedOptions).length);
    console.log("options", options.length);
    const inputData = optionsinput.reduce((acc, option, index) => {
      acc[option] = inputRefs[index]?.value || "";
      return acc;
    }, {});
    if (listValue.length === 0) {
      setErrorMessage2(
        "لطفا صبر کنید تا گیت محصولات را نمایش بدهد و بعد ثبت کنید"
      );
    } else if (
      Object.keys(newSelectedOptions).length != options.length ||
      Object.values(inputData).some((value) => !value)
    ) {
      setErrorMessage2("لطفا تمام موارد را انتخاب کنید");
    } else {
      const send = {
        ...inputData,
        ...newSelectedOptions,
        id_tag: listValue,
      };
      console.log("موارد انتخاب شده:", send);

      const formData = {
        selects: selectedOptions,
        inputs: inputData,
      };

      console.log("Form Data:", formData);
      for (const code in newSelectedOptions) {
        if (!newSelectedOptions[code]) {
          const errorMessageCode = `لطفاً یک مقدار برای ${code} انتخاب کنید.`;
          alert(errorMessageCode);
          console.error(errorMessageCode);
          return;
        }
      }
      setErrorMessage2("");
      DataSend(readerid, send);
    }
  };

  // const Chart = () => {
  // const [chartOptions, setChartOptions] = useState({
  //   chart: {
  //     // height: 500,
  //     // type: "area"
  //     // stacked: true
  //   },
  //   dataLabels: {
  //     enabled: false
  //   },
  //   colors: ['#32A4FC', '#00BA88'],
  //   series: [
  //     {
  //       name: " کل محصولات",
  //       data: []
  //     },
  //     {
  //       name: " نوع محصولات ",
  //       data: []
  //     }
  //   ],
  //   fill: {
  //     type: "gradient",
  //     gradient: {
  //       shadeIntensity: 1,
  //       opacityFrom: 0.7,
  //       opacityTo: 0.9,
  //       stops: [0, 90, 100]
  //     }
  //   },
  //   xaxis: {
  //     categories: []
  //     // categories: [ "1403/01/09 17:09:14dsdsdsdsd", "1403/01/09 17:16:18" ]
  //   }
  // });

  // useEffect(() => {
  //   // const updateRows = () => {

  //   if (cont.length !== 0) {
  //     setTimeout(() => {
  //       // console.log(chartData)
  //       // const qeimatTextElement = document.getElementById("qeimat-text");
  //       // const discountElement = document.getElementById("discount");
  //       // const loaderElement = document.getElementById("loader");
  //       const loadingElement = document.getElementById("loading");
  //       const nextLoadingElement = document.getElementById("next-loading");
  //       //
  //       // qeimatTextElement.style.display = "block";
  //       //
  //       // discountElement.style.display = "block";
  //       //
  //       // document.head.appendChild(styleElement);
  //       //
  //       // loaderElement.style.display = "block";
  //       loadingElement.style.display = "none";
  //       nextLoadingElement.style.display = "contents";
  //     }, 5000);
  //   }
  //   // };
  //   // updateRows();
  // }, [cont]);

  // const [currentTime, setCurrentTime] = useState('');

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const jalaliDate = moment().locale('fa').format('jYYYY/jMM/jDD HH:mm');
  //     setCurrentTime(jalaliDate);
  //   }, 1000);
  //
  //   return () => clearInterval(interval);
  // }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      const jalaliDate = moment().locale("fa").format("jYYYY/jMM/jDD HH:mm");
      setCurrentTime(jalaliDate);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const currentTime1 = new Date();
  const faTime = currentTime1.getHours() + ":" + currentTime1.getMinutes();
  const enTime = currentTime1.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  // const getDatesFromChartData = () => {
  //   const { categories } = chartData.options.xaxis;
  //   console.log(categories)
  //   return categories;
  // };

  const calculateDateDifference = () => {
    // const dates = getDatesFromChartData();
    console.log("Categories:", dates);
    if (startDate && endDate) {
      const difference = differenceInDays(
        new Date(endDate),
        new Date(startDate)
      );
      console.log(`تفاوت تاریخ: ${difference} روز`);
      if (difference >= 0) {
        const formattedStartDate = moment(startDate.toDate())
          .locale("fa")
          .format("YYYY/MM/DD");
        const formattedEndDate = moment(endDate.toDate())
          .locale("fa")
          .format("YYYY/MM/DD");

        console.log("تاریخ دیت پیکر اول:", formattedStartDate);
        console.log("تاریخ دیت پیکر دوم:", formattedEndDate);

        setFirstDate(formattedStartDate);
        setLastDate(formattedEndDate);

        // const dates = [
        //   "1403/01/09 17:09:14",
        //   "1403/01/10 17:16:18",
        //   "1403/01/11 17:16:18",
        //   "1403/02/02 17:16:18",
        //   "1403/02/13 17:16:18",
        //   "1403/02/14 17:16:18",
        //   "1404/03/09 17:16:18",
        //   "1404/03/12 17:16:18"
        // ];

        // const getDatesFromChartData = () => {
        //   const { categories } = chartData.options.xaxis;
        //   return categories;
        // };
        // const { categories } = chartData.options.xaxis;
        // const dates = categories;
        // Call the function to get dates
        // const dates = getDatesFromChartData();
        // console.log(chartData.options.xaxis.categories)
        // const dates = [...chartData.options.xaxis.categories]
        const dates1 = dates;
        console.log("dates1", dates1);
        // Array(4) [ "1403/01/11 17:16:18", "1403/02/02 17:16:18", "1403/02/13 17:16:18", "1403/02/14 17:16:18" ]
        const indicesToRemove = [];
        const indicesToRemove2 = [];
        const filterDates = dates1.filter((date, index) => {
          const parsedDate = new Date(
            date.replace(/(\d+)\/(\d+)\/(\d+)/, "$1-$2-$3")
          );
          const fromDate = new Date(formattedStartDate);
          const toDate = new Date(formattedEndDate);

          // return parsedDate >= fromDate && parsedDate <= toDate;
          if (parsedDate >= fromDate && parsedDate <= toDate) {
            return true;
          } else {
            console.log(index);
            indicesToRemove.push(index);
            indicesToRemove2.push(index);
            return false;
          }
        });

        const otherListCont = [...seriescont];
        const otherListIndex = [...seriesindex];

        // حذف اندیس‌های مورد نظر از لیست دوم
        indicesToRemove2.reverse().forEach((index) => {
          otherListCont.splice(index, 1);
        });

        indicesToRemove.reverse().forEach((index) => {
          otherListIndex.splice(index, 1);
        });

        setChartData({
          series: [
            { data: [...otherListCont], name: "کل محصولات" },
            { data: [...otherListIndex], name: "نوع محصولات" },
          ],
          options: {
            ...chartData.options,
            xaxis: {
              ...chartData.options.xaxis,
              // categories: [1,3]
              categories: filterDates,
            },
          },
        });
        const sum_cont = otherListCont.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        const sum_index = otherListIndex.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        setCont([sum_cont, sum_index]);
        console.log("filterDates", filterDates);
        console.log("otherListCont", otherListCont);
        console.log("otherListIndex", otherListIndex);
        console.log("seriescont", seriescont);
        console.log("seriesindex", seriesindex);
        setErrorMessage3("");
      } else {
        setErrorMessage3("بازه زمانی اشتباه انتخاب کردید");
      }
    }
  };
  // const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  // const handleWarehouseChange = (selectedOption) => {
  //   setSelectedWarehouse(selectedOption);
  // };

  // useEffect(() => {
  // در اینجا می‌توانید از API درخواست بفرستید و داده‌ها را دریافت کنید
  // سپس با تغییر وضعیت (setState) داده‌های دریافتی را به وضعیت اضافه کنید
  // برای مثال، فرض کنید داده‌هایی که ارائه داده‌شده‌اید در state.options ذخیره شده‌اند
  // const dataFromApi = [
  //   {
  //     name: ["سعید", "جواد"],
  //     code: "نام",
  //   },
  //   {
  //     name: ["صبح", " عصر"],
  //     code: "شیفت",
  //   },
  //   {
  //     name: ["تست"],
  //     code: "مدل",
  //   },
  // ];

  // const options = dataFromApi.map((item) => ({
  //   value: item.name.map((name) => ({ value: name, label: name })),
  //   label: item.code,
  // }));

  // setOptions(options);
  // }, []);
  const handleSelectChange = (option, index) => {
    console.log("Selected option:", option.label);
    console.log("Selected option:", option.value);
    console.log("Index:", index);
    setSelectedOptions({ ...selectedOptions, [option.value]: option.label });
  };

  const inputRefs = [];
  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        ariaHideApp={false}
        className="custom-modal">
        <div className="layer-back">
          <div className="layer-middle">
            <div className="layer-top">
              <div className={"img-reader"}>
                <img src={reader}></img>
              </div>
              <div className="list">
                <p>
                  شماره Reader در دسترس شما{" "}
                  {JSON.parse(localStorage.getItem("user"))} جان
                </p>
                {JSON.parse(localStorage.getItem("reader")).map(
                  (option, index) => (
                    <p>{option}</p>
                  )
                )}

                <ul>
                  <li>شماره Reader مورد نظر را وارد کنید</li>
                </ul>
              </div>
              <form className="formModal" onSubmit={handleSubmit(onFormSubmit)}>
                <section
                  className={`form-item ${errors.readername ? "error" : ""}`}>
                  <div
                    className={`input-wrapper ${
                      touchedFields.readername ? "active" : ""
                    } ${errors.readername ? "error" : ""}`}>
                    <input
                      {...register("readername", {
                        required: "شماره ریدر را درست وارد کنید",
                      })}
                      className={
                        errors.username ? "error input-reader" : "input-reader"
                      }
                    />
                    <label htmlFor="readername" className="placeholder">
                      نام ریدر
                    </label>
                  </div>
                  {touchedFields.readername && errors.readername && (
                    <div className="error-message">
                      {errors.readername.message}
                    </div>
                  )}
                </section>
                {errorMessage && (
                  <div className="error-message">{errorMessage}</div>
                )}
                <div className="buttons">
                  <button
                    type="submit"
                    className="icon-button"
                    disabled={!isValid}>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6 9H12"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9 12V6"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    ثبت درخواست
                  </button>
                  <button
                    className="icon-button logoutButton"
                    onClick={LoginForm}>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.67578 5.66999C6.90828 2.96999 8.29578 1.86749 11.3333 1.86749H11.4308C14.7833 1.86749 16.1258 3.20999 16.1258 6.56249V11.4525C16.1258 14.805 14.7833 16.1475 11.4308 16.1475H11.3333C8.31828 16.1475 6.93078 15.06 6.68328 12.405"
                        stroke="#014274"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.2498 9H2.71484"
                        stroke="#014274"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4.3875 6.4875L1.875 9L4.3875 11.5125"
                        stroke="#014274"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    خروج
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={openSecondModal}
        onRequestClose={closeModal2}
        ariaHideApp={false}
        className="custom-modal">
        <div className="layer-back">
          <div className="layer-middle">
            <div className="layer-top">
              <div className="img-reader2">
                <img className="imgreader2" src={afsharImg}></img>
              </div>
              <form onSubmit={handleSubmit0}>
                <div className="list">
                  <ul>
                    <li>اطلاعات تکمیلی محصول مورد نظر را انتخاب کنید.</li>
                  </ul>

                  <div className="drop2">
                    {options.map((option, index) => (
                      <Select
                        key={index}
                        options={option.value}
                        isDisabled={option.value.length === 1}
                        defaultValue={
                          option.value.length === 1 ? option.value[0] : null
                        }
                        placeholder={option.label}
                        onChange={(selectedOption) =>
                          handleSelectChange(selectedOption, index)
                        }
                      />
                    ))}

                    {optionsinput.map((option, index) => (
                      <section key={index} className={`form-item`}>
                        <div className="input-wrapper">
                          <input
                            ref={(el) => (inputRefs[index] = el)}
                            className="input-reader"
                            // placeholder={option}
                          />
                          <label className="placeholder">{option}</label>
                        </div>
                      </section>
                    ))}
                  </div>
                </div>

                <div className="icon-text-container">
                  <img src={plusbutton} alt="plus button" />
                  <p>
                    جهت اضافه کردن موارد بیشتر به{" "}
                    <b className="bold">پشتیبانی</b> مراجعه کنید
                  </p>
                </div>
                {errorMessage2 && (
                  <div className="error-message">{errorMessage2}</div>
                )}
                <div className="buttons">
                  {type != "خودکار" ? (
                    <button type="submit" className="icon-button">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6 9H12"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9 12V6"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      ثبت محصول
                    </button>
                  ) : null}

                  <button
                    type="button"
                    className="icon-button logoutButton"
                    onClick={closeModal2}>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.67578 5.66999C6.90828 2.96999 8.29578 1.86749 11.3333 1.86749H11.4308C14.7833 1.86749 16.1258 3.20999 16.1258 6.56249V11.4525C16.1258 14.805 14.7833 16.1475 11.4308 16.1475H11.3333C8.31828 16.1475 6.93078 15.06 6.68328 12.405"
                        stroke="#014274"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.2498 9H2.71484"
                        stroke="#014274"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.3875 6.4875L1.875 9L4.3875 11.5125"
                        stroke="#014274"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    خروج
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
      <div className="fakeimg header-4">
        <p>
          خوش آمدید {JSON.parse(localStorage.getItem("user"))} جان. شماره ریدر
          شما: {readerid} است و نوع سخت افزار شما: {type} است.
        </p>
        <div className="header-4-b">
          {type != "خودکار" ? (
            <button
              className="bi bi-check-lg"
              style={{ width: "56px" }}
              onClick={postToApi}></button>
          ) : (
            <button
              className="bi bi-file-earmark-text"
              style={{ width: "56px" }}
              onClick={postToApi}></button>
          )}
          <button
            className="bi bi-arrow-clockwise"
            style={{ width: "56px" }}
            onClick={refreshPage}></button>
          <button
            className="bi bi-box-arrow-left"
            style={{ width: "56px" }}
            onClick={LoginForm}></button>
        </div>
      </div>
      <div className="row">
        <div className="main">
          <div className="f-glass">
            <div className="tbl-content" id="scrollableDiv">
              <table cellPadding="0" cellSpacing="0" border="0">
                <thead>
                  <tr style={{ background: "none" }}>{thElements}</tr>
                </thead>
                <tbody>
                  {/*{products.map((product, index) => (*/}
                  {/*    <tr key={index}>*/}
                  {/*      <td>{product.name}</td>*/}
                  {/*      <td>*/}
                  {/*        {convertToPersianNumber(product.price.toLocaleString())}*/}
                  {/*      </td>*/}
                  {/*      <td>*/}
                  {/*        {convertToPersianNumber(*/}
                  {/*            product.quantity.toLocaleString()*/}
                  {/*        )}*/}
                  {/*      </td>*/}
                  {/*      <td>*/}
                  {/*        {convertToPersianNumber(*/}
                  {/*            product.discount.toLocaleString()*/}
                  {/*        )}*/}
                  {/*      </td>*/}
                  {/*      <td>*/}
                  {/*        <div className="div-discount">*/}
                  {/*          {convertToPersianNumber(*/}
                  {/*              (*/}
                  {/*                  product.quantity * product.price -*/}
                  {/*                  product.discount*/}
                  {/*              ).toLocaleString()*/}
                  {/*          )}*/}
                  {/*        </div>*/}
                  {/*      </td>*/}
                  {/*    </tr>*/}
                  {/*))}*/}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="side">
          {/* <div
            className="fakeimg"
            style={{ height: "50px", alignItems: "center" }}>
            
            
          </div> */}

          <div
            className="fakeimg"
            style={{ height: "76%", flexDirection: "column" }}>
            {cont.length !== 0 ? (
              <div
                id="next-loading"
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}>
                <div className="div-chart">
                  <div className="item-div">
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        justifyContent: "start",
                      }}>
                      <button
                        className="bi bi-list-check "
                        style={{ backgroundColor: "#00BA88" }}></button>
                      <h3>{cont[1]}</h3>
                    </div>
                    <p>تعداد نوع محصول</p>
                  </div>
                  <div className="item-div">
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        justifyContent: "start",
                      }}>
                      <button className="bi bi-card-checklist "></button>
                      <h3>{cont[0]}</h3>
                    </div>
                    <p>تعداد کل محصولات</p>
                  </div>
                </div>
                <div className="bi bi-clock-history clock-time">
                  بازده زمانی
                </div>
                <div className="DatePicker">
                  <div className="time-check">
                    <button className="time-check-button1">1 س</button>
                    <button className="time-check-button1">6 س</button>
                    <button className="time-check-button2">12 س</button>
                    <button className="time-check-button3">روزانه</button>
                  </div>
                  <div className="DatePicker-div">
                    <DatePicker
                      animations={[
                        opacity(),
                        transition({ from: 35, duration: 800 }),
                      ]}
                      calendar={persian}
                      locale={persian_fa}
                      calendarPosition="bottom-right"
                      value={startDate}
                      onChange={handleStartDateChange}
                    />
                    -
                    <DatePicker
                      animations={[
                        opacity(),
                        transition({ from: 35, duration: 800 }),
                      ]}
                      calendar={persian}
                      locale={persian_fa}
                      calendarPosition="bottom-right"
                      value={endDate}
                      onChange={handleEndDateChange}
                    />
                    <button
                      onClick={calculateDateDifference}
                      style={{ width: "56px" }}
                      className="bi bi-search"></button>
                  </div>
                </div>
                {errorMessage3 && (
                  <div className="error-message">{errorMessage3}</div>
                )}
                <div id="chart">
                  <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="area"
                    height={300}
                  />
                </div>
              </div>
            ) : (
              <div id="loading" style={{ display: "contents" }}>
                <div className="wrapper">
                  <div className="circle"></div>
                  <div className="circle"></div>
                  <div className="circle"></div>
                  <div className="shadow"></div>
                  <div className="shadow"></div>
                  <div className="shadow"></div>
                </div>
              </div>
            )}
          </div>
          <div
            className="fakeimg2"
            style={{ height: "112px", flexDirection: "row" }}>
            <div
              className="fakeimg"
              style={{
                height: "120px",
                flexDirection: "column",
                width: "70%",
                marginTop: "0",
              }}>
              <div className="calender">
                <DateDisplay />
                <WeekCalendar />
              </div>
            </div>
            <div
              className="fakeimg"
              style={{
                height: "120px",
                flexDirection: "column",
                width: "30%",
                marginTop: "0",
              }}>
              <div className="analogclock-flex">
                <div className="digi">
                  <div className="digital-clocks">
                    <p>فا</p>
                    <div>{faTime}</div>
                  </div>
                  <div className="digital-clocks">
                    <p>EN</p>
                    <div style={{ direction: "ltr", fontFamily: "sans-serif" }}>
                      {enTime}
                    </div>
                  </div>
                </div>
                <AnalogClock />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorePage;

//
//
// import "./App.css"; // وارد کردن فایل CSS
// import jalaali from "jalaali-js";
// import React, { useState, useEffect } from "react";
// import Cookies from 'js-cookie';
// import Modal from "react-modal";
// import reader from "../assets/img/reader.svg";
// import afsharImg from "../assets/img/afsharImg.svg";
// import {useNavigate} from "react-router-dom";
// import { useForm } from "react-hook-form";
// import {
//   Dropdown,
//   Link,
//   DropdownTrigger,
//   DropdownMenu,
//   DropdownItem,
//   Button,
// } from "@nextui-org/react";
// const StorePage = () => {
//   const [listId, setListId] = useState([]);
//   const [listValue, setListValue] = useState([]);
//   const [sum, setSum] = useState(0);
//   const [sum2, setSum2] = useState(0);
//   const [sumDiscount, setSumDiscount] = useState(0);
//   const [z, setZ] = useState(1);
//   const [scrollTop, setScrollTop] = useState(0);
//   const [readerid, setreaderid] = useState(0);
//   const scrollSpeed = 2; // سرعت اسکرول (به پیکسل در ثانیه)
//
//   const [products, setProducts] = useState([]);
//
//
//   const csrfToken = Cookies.get('csrfToken');
//   const [openModal, setOpenModal] = useState(false);
//   const [inputValue, setInputValue] = useState(""); // State برای نگهداری مقدار ورودی
//   const [isInputFilled, setIsInputFilled] = useState(false);
//
//   const [openSecondModal, setOpenSecondModal] = useState(false);
//
//   const [text, setText] = useState("");
//
//
//     const {
//     register,
//     handleSubmit,
//     setError,
//     setvalue,
//     formState: { errors, touchedFields, isValid },
//     triggerValidation,
//   } = useForm({
//     mode: "onChange",
//   });
//
//
//   /*api test jwd start*/
//   const fetchData = async () => {
//     const tokenString = localStorage.getItem("token");
//
//     try {
//       const tokenObject = JSON.parse(tokenString);
//       const accessToken = tokenObject.access;
//
//       const response = await fetch("http://127.0.0.1:8000/api/", {
//         method: "GET",
//         headers: {
//           "X-CSRFToken": csrfToken,
//           "Authorization": `Bearer ${accessToken}`
//         },
//       });
//
//       if (response.ok) {
//         const data = await response.json();
//         console.log(data);
//       }else if (response.status === 401){
//         await handleInvalidToken();
//       } else {
//         console.error("Submission failed", response.statusText);
//       }
//     } catch (error) {
//       console.error("An error occurred", error);
//     }
//   };
//
//
//   const handleInvalidToken = async () => {
//       const tokenString = localStorage.getItem("token");
//       try {
//           const tokenObject = JSON.parse(tokenString);
//           const refreshToken = tokenObject.refresh;
//           const formData = new URLSearchParams();
//           formData.append("refresh", refreshToken);
//           const response = await fetch("http://127.0.0.1:8000/accounts/token/refresh/", {
//               method: "POST",
//               headers: {
//                   "Content-Type": "application/x-www-form-urlencoded",
//                   "X-CSRFToken": csrfToken,
//                   // Add any other headers if needed
//               },
//               body:  formData.toString(),
//           });
//
//           if (response.ok) {
//               const data = await response.json();
//               tokenObject.access = data.access;
//               localStorage.setItem("token", JSON.stringify(tokenObject));
//               console.log("Another API data:", data.access);
//               fetchData();
//           } else {
//               console.error("Another API request failed", response.statusText);
//               await LoginForm();
//           }
//       } catch (error) {
//           console.error("An error occurred while calling another API", error);
//       }
//   };
//
//   /*api test jwd end*/
//
//
//
//
//   useEffect(() => {
//     let list1 = [];
//     fetchData();
//     const url =  `ws://127.0.0.1:8000/ws/socket-server/`;
//     const chatSocket = new WebSocket(url);
//
//     chatSocket.onmessage = function (e) {
//       let djangoData = JSON.parse(e.data);
//       console.log('Data:', djangoData);
//       console.log("readerid",readerid)
//       console.log(djangoData.type === readerid)
//       if (djangoData.type === readerid) {
//
//         setListId((prevListId) => [...prevListId, djangoData.id]);
//         setListValue((prevListValue) => [...prevListValue, djangoData.value]);
//
//         console.log('value:',djangoData.value);
//         console.log('id:',djangoData.id)
//         console.log('name:', djangoData.name)
//         console.log('price:', djangoData.price)
//         console.log('discount:', djangoData.discount)
//
//         const tableBody = document.querySelector("table tbody");
//
//         console.log(products);
//         const existingProductIndex = list1.filter(
//           (item) => item === djangoData.value
//         ).length;
//         console.log(existingProductIndex);
//         if (existingProductIndex > 0) {
//           let index = list1.indexOf(djangoData.value);
//           console.log(index);
//           setProducts((prevProducts) => {
//             const updatedProducts = [...prevProducts];
//             updatedProducts[index].quantity += 1;
//             return updatedProducts;
//           });
//         } else {
//           list1.push(djangoData.value);
//           setProducts((prevProducts) => [
//             ...prevProducts,
//             {
//               id: djangoData.id,
//               name: djangoData.name,
//               quantity: 1, // تعداد اولیه یک است
//               price: djangoData.price,
//               discount: djangoData.discount,
//             },
//           ]);
//         }
//
//       }
//     };
//
//     const handleBeforeUnload = (event) => {
//       chatSocket.send(
//         JSON.stringify({
//           message: 'saeed', // برای این هست که اگه این پیام به بکند ارسال بشه لیست کامل پاک بشه و دوباره کانال وب سوکت وصل بشه
//           idsocet: readerid
//         })
//       );
//     };
//
//     window.addEventListener('beforeunload', handleBeforeUnload);
//
//     // Cleanup function
//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//       chatSocket.close();
//     };
//   }, [readerid]);
//
//
//   useEffect(() => {
//     const updateRows = () => {
//       let totalSum = 0;
//       let totalSum2 = 0;
//       let totalDiscount = 0;
//
//       const tableBody = document.querySelector("table tbody");
//       tableBody.innerHTML = ""; // پاک کردن ردیف‌های قبلی
//
//       products.forEach((product) => {
//         const row = document.createElement("tr");
//         const nameCell = document.createElement("td");
//         const quantityCell = document.createElement("td");
//         const priceCell = document.createElement("td");
//         const discountCell = document.createElement("td");
//         const totalCell = document.createElement("td");
//
//         nameCell.textContent = product.name;
//         quantityCell.textContent = convertToPersianNumber(
//           product.quantity.toLocaleString()
//         );
//         priceCell.textContent = convertToPersianNumber(
//           product.price.toLocaleString()
//         );
//         discountCell.innerHTML =
//           '<div class="div-discount">' +
//           convertToPersianNumber(
//             (
//               product.quantity *
//               (product.price - product.discount)
//             ).toLocaleString()
//           ) +
//           "</div>";
//         totalCell.textContent = convertToPersianNumber(
//           product.discount.toLocaleString()
//         );
//         totalSum += product.quantity * (product.price - product.discount);
//         totalSum2 += product.quantity * product.price;
//         totalDiscount += product.discount;
//
//         row.appendChild(nameCell);
//         row.appendChild(priceCell);
//         row.appendChild(quantityCell);
//         row.appendChild(totalCell);
//         row.appendChild(discountCell);
//
//         tableBody.appendChild(row);
//       });
//
//
//       const qeimatElement = document.getElementById("qeimat");
//       const qeimatKolElement = document.getElementById("qeimat-kol");
//       const discountElement = document.getElementById("discount");
//
//       const newSum = totalSum;
//       const newSum2 = totalSum2;
//       const newSumDiscount = totalDiscount;
//
//       setSum(newSum);
//       setSum2(newSum2);
//       setSumDiscount(newSumDiscount);
//
//       qeimatElement.innerHTML = convertToPersianNumber(newSum.toLocaleString());
//       qeimatKolElement.innerHTML =
//         "قیمت کل : " + convertToPersianNumber(newSum2.toLocaleString());
//
//       discountElement.innerHTML =
//         convertToPersianNumber(
//           ((newSumDiscount / newSum2) * 100).toLocaleString()
//         ) + "%";
//
//       const loaderPercentage =
//         ((newSumDiscount / newSum2) * 100).toLocaleString() + "%";
//       const styleElement = document.createElement("style");
//       styleElement.innerHTML = `#loader::before {
//         width: ${loaderPercentage};
//       }`;
//
//       document.head.appendChild(styleElement);
//
//       if(newSum2 != 0){
//         setTimeout(() => {
//             const qeimatTextElement = document.getElementById("qeimat-text");
//             const discountElement = document.getElementById("discount");
//             const loaderElement = document.getElementById("loader");
//             const loadingElement = document.getElementById("loading");
//             const nextLoadingElement = document.getElementById("next-loading");
//
//             qeimatTextElement.style.display = "block";
//
//             discountElement.style.display = "block";
//
//             document.head.appendChild(styleElement);
//
//             loaderElement.style.display = "block";
//             loadingElement.style.display = "none";
//             nextLoadingElement.style.display = "contents";
//           }, 5000);
//       }
//     };
//
//     // هر بار که مقدار products به‌روزرسانی می‌شود، تابع updateRows را فراخوانی می‌کنیم
//     updateRows();
//   }, [products]);
//
//   function convertToPersianNumber(number) {
//     const persianNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
//     const englishNumbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
//
//     let convertedNumber = "";
//
//     for (let i = 0; i < number.length; i++) {
//       const index = englishNumbers.indexOf(number[i]);
//       if (index !== -1) {
//         convertedNumber += persianNumbers[index];
//       } else {
//         convertedNumber += number[i];
//       }
//     }
//
//     return convertedNumber;
//   }
//
//   /////////////////////////////////////////////////////////////////////////////////////////////////////
//   const [currentTime, setCurrentTime] = useState(new Date());
//
//   useEffect(() => {
//     const intervalID = setInterval(() => tick(), 1000);
//     return () => clearInterval(intervalID);
//   }, []);
//
//   const tick = () => {
//     setCurrentTime(new Date());
//   };
//   const gDate = currentTime;
//   const jDate = jalaali.toJalaali(
//     gDate.getFullYear(),
//     gDate.getMonth() + 1,
//     gDate.getDate()
//   );
//   const jalaliDate = `${jDate.jy}/${jDate.jm}/${jDate.jd}`;
//   const currentTimeString = `${gDate.getHours()}:${gDate.getMinutes()}`;
//
//   const days = [
//     "یکشنبه",
//     "دوشنبه",
//     "سه‌شنبه",
//     "چهارشنبه",
//     "پنج‌شنبه",
//     "جمعه",
//     "شنبه",
//   ];
//   const today = new Date();
//   const dayName = days[today.getDay()];
//   //////////////////////////////////////////////////////////////////////////////////////////////
//
//   const refreshPage = async () => {
//     console.log(1);
//     window.location.reload();
//   };
//
//   const postToApi = () => {
//         fetch('http://127.0.0.1:8000/api/', {
//           method: 'POST',
//           body: JSON.stringify({ value: listValue }),
//           headers: {
//               'Content-Type': 'application/json',
//               'X-CSRFToken': csrfToken
//               // این باید با jwd درست بشه که هنوز درست نشده و کار نمیکنه
//           }
//       })
//         .then(response => response.json())
//         .then(data => {
//           console.log(listValue)
//           refreshPage()
//         })
//     };
//   const navigate = useNavigate()
//   const LoginForm = () => {
//     const tokenString = localStorage.getItem("token");
//     const tokenObject = JSON.parse(tokenString);
//     const accessToken = tokenObject.access;
//     const refreshToken = tokenObject.refresh;
//     const formData = new URLSearchParams();
//     formData.append("refresh_token", refreshToken);
//         fetch('http://127.0.0.1:8000/accounts/logout/', {
//           method: 'POST',
//           headers: {
//               'Content-Type': 'application/x-www-form-urlencoded',
//               'X-CSRFToken': csrfToken,
//               "Authorization": `Bearer ${accessToken}`
//           },
//           body:formData.toString(),
//       })
//         .then(response => response.json())
//         .then(data => {
//           console.log('logout')
//           localStorage.removeItem("token");
//           // localStorage.removeItem("access");
//           // window.location.replace('http://127.0.0.1:8000/login');
//           navigate('/login')
//         })
//     };
//
//   const setMobileTable = (selector) => {
//     const tableEl = document.querySelector(selector);
//     const thEls = tableEl.querySelectorAll("thead th");
//     const tdLabels = Array.from(thEls).map((el) => el.innerText);
//     tableEl.querySelectorAll("tbody tr").forEach((tr) => {
//       Array.from(tr.children).forEach((td, ndx) =>
//         td.setAttribute("label", tdLabels[ndx])
//       );
//     });
//   };
//
//   useEffect(() => {
//     setMobileTable("table");
//   }, [products]);
//   // const readerData = new URLSearchParams();
//   const onFormSubmit = async (data) => {
//     setOpenModal(false);
//     console.log(data.readername)
//     // readerData.append("id", data.readername);
//     setreaderid(data.readername)
//   };
//
//   useEffect(() => {
//     setOpenModal(true);
//   }, []);
//
//   const closeModal = () => {
//     setOpenModal(false);
//   };
//   return (
//       <div>
//         <Modal
//             isOpen={openModal}
//             onRequestClose={closeModal}
//             ariaHideApp={false}
//             className="custom-modal"
//         >
//           <div className="layer-back"></div>
//           <div className="layer-middle"></div>
//           <div className="layer-top">
//             <div className={"img-reader"}>
//               <img src={reader}></img>
//             </div>
//             <div className="list">
//               <ul>
//                 <li>شماره Reader مورد نظر را وارد کنید</li>
//               </ul>
//             </div>
//             <form className="formModal" onSubmit={handleSubmit(onFormSubmit)}>
//               <section
//                   className={`form-item ${errors.readername ? "error" : ""}`}
//               >
//                 <div
//                     className={`input-wrapper ${
//                         touchedFields.readername ? "active" : ""
//                     } ${errors.readername ? "error" : ""}`}
//                 >
//                   <input
//                       {...register("readername", {
//                         required: "شماره ریدر را درست وارد کنید",
//                       })}
//                       className={errors.username ? "error input-reader" : "input-reader"}
//                   />
//                   <label htmlFor="readername" className="placeholder">
//                     نام ریدر
//                   </label>
//                 </div>
//                 {touchedFields.readername && errors.readername && (
//                     <div className="error-message">{errors.readername.message}</div>
//                 )}
//               </section>
//               <div className="buttons">
//                 <button type="submit" className="icon-button" disabled={!isValid}>
//                   <svg
//                       width="18"
//                       height="18"
//                       viewBox="0 0 18 18"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                         d="M6 9H12"
//                         stroke="white"
//                         stroke-width="1.5"
//                         stroke-linecap="round"
//                         stroke-linejoin="round"
//                     />
//                     <path
//                         d="M9 12V6"
//                         stroke="white"
//                         stroke-width="1.5"
//                         stroke-linecap="round"
//                         stroke-linejoin="round"
//                     />
//                     <path
//                         d="M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z"
//                         stroke="white"
//                         stroke-width="1.5"
//                         stroke-linecap="round"
//                         stroke-linejoin="round"
//                     />
//                   </svg>
//                   ثبت درخواست
//                 </button>
//                 <button className="icon-button logoutButton" onClick={LoginForm}>
//                   <svg
//                       width="18"
//                       height="18"
//                       viewBox="0 0 18 18"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                         d="M6.67578 5.66999C6.90828 2.96999 8.29578 1.86749 11.3333 1.86749H11.4308C14.7833 1.86749 16.1258 3.20999 16.1258 6.56249V11.4525C16.1258 14.805 14.7833 16.1475 11.4308 16.1475H11.3333C8.31828 16.1475 6.93078 15.06 6.68328 12.405"
//                         stroke="#014274"
//                         stroke-width="1.5"
//                         stroke-linecap="round"
//                         stroke-linejoin="round"
//                     />
//                     <path
//                         d="M11.2498 9H2.71484"
//                         stroke="#014274"
//                         stroke-width="1.5"
//                         stroke-linecap="round"
//                         stroke-linejoin="round"
//                     />
//                     <path
//                         d="M4.3875 6.4875L1.875 9L4.3875 11.5125"
//                         stroke="#014274"
//                         stroke-width="1.5"
//                         stroke-linecap="round"
//                         stroke-linejoin="round"
//                     />
//                   </svg>
//                   خروج
//                 </button>
//               </div>
//             </form>
//           </div>
//         </Modal>
//
//         <Modal
//             isOpen={openSecondModal}
//             onRequestClose={() => setOpenSecondModal(false)}
//             ariaHideApp={false}
//             className="custom-modal"
//         >
//           <div className="layer-back"></div>
//           <div className="layer-middle"></div>
//           <div className="layer-top">
//             <img className="img-reader2" src={afsharImg}></img>
//             <div className="list">
//               <ul>
//                 <li>یک گزینه از دسته بندی محصولات را انتخاب کنید</li>
//               </ul>
//               <Dropdown backdrop="blur">
//                 <DropdownTrigger>
//                   <Button variant="bordered">محصول</Button>
//                 </DropdownTrigger>
//                 <DropdownMenu variant="faded" aria-label="Static Actions">
//                   <DropdownItem key="new">هودی</DropdownItem>
//                   <DropdownItem key="copy">شلوار</DropdownItem>
//                   <DropdownItem key="edit">تیشرت</DropdownItem>
//                   <DropdownItem
//                       key="delete"
//                       className="text-danger"
//                       color="danger"
//                   >
//                     هیچکدام
//                   </DropdownItem>
//                 </DropdownMenu>
//               </Dropdown>
//
//               <ul>
//                 <li>اطلاعات تکمیلی محصول مورد نظر را انتخاب کنید.</li>
//               </ul>
//               <Dropdown backdrop="blur">
//                 <DropdownTrigger>
//                   <Button variant="bordered">محصول</Button>
//                 </DropdownTrigger>
//                 <DropdownMenu variant="faded" aria-label="Static Actions">
//                   <DropdownItem key="new">هودی</DropdownItem>
//                   <DropdownItem key="copy">شلوار</DropdownItem>
//                   <DropdownItem key="edit">تیشرت</DropdownItem>
//                   <DropdownItem
//                       key="delete"
//                       className="text-danger"
//                       color="danger"
//                   >
//                     هیچکدام
//                   </DropdownItem>
//                 </DropdownMenu>
//               </Dropdown>
//               <Dropdown backdrop="blur">
//                 <DropdownTrigger>
//                   <Button variant="bordered">محصول</Button>
//                 </DropdownTrigger>
//                 <DropdownMenu variant="faded" aria-label="Static Actions">
//                   <DropdownItem key="new">هودی</DropdownItem>
//                   <DropdownItem key="copy">شلوار</DropdownItem>
//                   <DropdownItem key="edit">تیشرت</DropdownItem>
//                   <DropdownItem
//                       key="delete"
//                       className="text-danger"
//                       color="danger"
//                   >
//                     هیچکدام
//                   </DropdownItem>
//                 </DropdownMenu>
//               </Dropdown>
//               <Dropdown backdrop="blur">
//                 <DropdownTrigger>
//                   <Button variant="bordered">محصول</Button>
//                 </DropdownTrigger>
//                 <DropdownMenu variant="faded" aria-label="Static Actions">
//                   <DropdownItem key="new">هودی</DropdownItem>
//                   <DropdownItem key="copy">شلوار</DropdownItem>
//                   <DropdownItem key="edit">تیشرت</DropdownItem>
//                   <DropdownItem
//                       key="delete"
//                       className="text-danger"
//                       color="danger"
//                   >
//                     هیچکدام
//                   </DropdownItem>
//                 </DropdownMenu>
//               </Dropdown>
//             </div>
//
//             <div className="buttons">
//               <button type="submit" className="icon-button" onClick={handleSubmit}>
//                 <svg
//                     width="18"
//                     height="18"
//                     viewBox="0 0 18 18"
//                     fill="none"
//                     xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                       d="M6 9H12"
//                       stroke="white"
//                       stroke-width="1.5"
//                       stroke-linecap="round"
//                       stroke-linejoin="round"
//                   />
//                   <path
//                       d="M9 12V6"
//                       stroke="white"
//                       stroke-width="1.5"
//                       stroke-linecap="round"
//                       stroke-linejoin="round"
//                   />
//                   <path
//                       d="M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z"
//                       stroke="white"
//                       stroke-width="1.5"
//                       stroke-linecap="round"
//                       stroke-linejoin="round"
//                   />
//                 </svg>
//                 ثبت محصول
//               </button>
//               <button
//                   type="submit"
//                   className="icon-button logoutButton"
//                   onClick={LoginForm}
//               >
//                 <svg
//                     width="18"
//                     height="18"
//                     viewBox="0 0 18 18"
//                     fill="none"
//                     xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                       d="M6.67578 5.66999C6.90828 2.96999 8.29578 1.86749 11.3333 1.86749H11.4308C14.7833 1.86749 16.1258 3.20999 16.1258 6.56249V11.4525C16.1258 14.805 14.7833 16.1475 11.4308 16.1475H11.3333C8.31828 16.1475 6.93078 15.06 6.68328 12.405"
//                       stroke="#014274"
//                       stroke-width="1.5"
//                       stroke-linecap="round"
//                       stroke-linejoin="round"
//                   />
//                   <path
//                       d="M11.2498 9H2.71484"
//                       stroke="#014274"
//                       stroke-width="1.5"
//                       stroke-linecap="round"
//                       stroke-linejoin="round"
//                   />
//                   <path
//                       d="M4.3875 6.4875L1.875 9L4.3875 11.5125"
//                       stroke="#014274"
//                       stroke-width="1.5"
//                       stroke-linecap="round"
//                       stroke-linejoin="round"
//                   />
//                 </svg>
//                 خروج
//               </button>
//             </div>
//           </div>
//         </Modal>
//
//         <div className="row">
//           <div className="main">
//             <div className="f-glass">
//               <div className="tbl-content" id="scrollableDiv">
//                 <table cellPadding="0" cellSpacing="0" border="0">
//                   <thead>
//                   <tr style={{background: "none"}}>
//                     <th>نام محصول</th>
//                     <th>قیمت واحد</th>
//                     <th>تعداد</th>
//                     <th>تخفیف</th>
//                     <th>قیمت نهایی</th>
//                   </tr>
//                   </thead>
//                   <tbody>
//                   {products.map((product, index) => (
//                       <tr key={index}>
//                         <td>{product.name}</td>
//                         <td>
//                           {convertToPersianNumber(product.price.toLocaleString())}
//                         </td>
//                         <td>
//                           {convertToPersianNumber(
//                               product.quantity.toLocaleString()
//                           )}
//                         </td>
//                         <td>
//                           {convertToPersianNumber(
//                               product.discount.toLocaleString()
//                           )}
//                         </td>
//                         <td>
//                           <div className="div-discount">
//                             {convertToPersianNumber(
//                                 (
//                                     product.quantity * product.price -
//                                     product.discount
//                                 ).toLocaleString()
//                             )}
//                           </div>
//                         </td>
//                       </tr>
//                   ))}
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//           </div>
//           <div className="side">
//             <div
//                 className="fakeimg"
//                 style={{height: "50px", alignItems: "center"}}
//             >
//               <button
//                   className="bi bi-check-lg"
//                   style={{width: "56px"}}
//                   onClick={postToApi}
//               ></button>
//               <button
//                   className="bi bi-arrow-clockwise"
//                   style={{width: "56px"}}
//                   onClick={refreshPage}
//               ></button>
//               <button
//                   className="bi bi-box-arrow-left"
//                   style={{width: "56px"}}
//                   onClick={LoginForm}
//               ></button>
//               {/* //////// */}
//             </div>
//
//             <div
//                 className="fakeimg"
//                 style={{height: "300px", flexDirection: "column", color: "#fff"}}
//             >
//               <div id="loading" style={{display: "contents"}}>
//                 <div className="wrapper">
//                   <div className="circle"></div>
//                   <div className="circle"></div>
//                   <div className="circle"></div>
//                   <div className="shadow"></div>
//                   <div className="shadow"></div>
//                   <div className="shadow"></div>
//                 </div>
//               </div>
//               <div id="next-loading" style={{display: "none", height: "100%"}}>
//                 <div style={{justifyContent: "space-evenly", display: "flex"}}>
//                   <div id="qeimat-text" style={{display: "none"}}>
//                     قیمت نهایی:
//                   </div>
//                   <div id="qeimat">
//                     {convertToPersianNumber(sum.toLocaleString())}
//                   </div>
//                   {/*style={{fontSize: '50px'}}*/}
//                 </div>
//                 <div style={{justifyContent: "space-evenly", display: "flex"}}>
//                   <div id="qeimat-kol">
//                     قیمت کل : {convertToPersianNumber(sum2.toLocaleString())}
//                   </div>
//                   <button
//                       className="fa bi-arrow-up fa-2x"
//                       id="discount"
//                       style={{display: "none"}}
//                   >
//                     {convertToPersianNumber(
//                         ((sumDiscount / sum2) * 100).toLocaleString()
//                     ) + "%"}
//                   </button>
//                 </div>
//                 <div style={{justifyContent: "space-evenly", display: "flex"}}>
//                   <div id="loader" style={{display: "none"}}></div>
//                 </div>
//               </div>
//             </div>
//             <div
//                 className="fakeimg"
//                 style={{height: "155px", flexDirection: "column"}}
//             >
//               <div id="flex-tarikh">
//                 <div id="day">{dayName}</div>
//                 {/*style={{fontSize: '40px'}}*/}
//                 <div id="tarikh">{jalaliDate}</div>
//                 {/*style={{fontSize: '28px'}}*/}
//               </div>
//               <div id="flex-sat">{currentTimeString}</div>
//             </div>
//             <div className="fakeimg" style={{height: "158px"}}></div>
//           </div>
//         </div>
//       </div>
//   );
// };
//
// export default StorePage;
