import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ArrowLeft2 } from "iconsax-react";
import image from "../../../src/assets/img/folded-hooded-sweatshirt-pile-black-UZK22UF 1.png";

import "./CardStore.css";

const CardStore = (props) => {
  const id = props.id;

  const name = props.name;

  const date = props.data.slice(0, 10);

  const time = props.data.slice(11);

  const mojodi = props.mojodi;

  return (
    <div className="store-card">
      <div className="first-sec-card">
        <div className="sec-one">
          <div className="icon-one">
            <i class="bi bi-funnel icon-filter"></i>
          </div>
          <h5> {name} </h5>
        </div>
        <div className="sec-two">
          <i class="bi bi-calendar2-event"></i>
          <p className="Body-MD">آخرین ثبت</p>
        </div>
        <div className="sec-three">
          <p className="caption-md"> - {date}</p>
          <p className="caption-md"> - {time}</p>
        </div>

        <div className="square">
          <img className="image-card" src={image} />
          <div className="seen">
            <button className="nav-button Button-SM">
              <Link to={`/view/${id}`}>
                مشاهده
                <ArrowLeft2 size="16" color="#014274" />
              </Link>
            </button>
          </div>
        </div>
        <div className="overlay">
          <p>موجودی : {mojodi} عدد</p>
        </div>
      </div>
    </div>
  );
};

export default CardStore;
