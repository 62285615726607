import React, { useState } from "react";
// import { InventoryProvider } from "./context/InventoryContext";

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import StorePage from "./StorePage/StorePage";
import LoginForm from "./LoginForm/LoginForm";
import { RequireAuth } from "./core/AuthGaurd";
import { Layout } from "./core/Layout";
import HomePage from "./Pages/HomePage/HomePage";
import Profile from "./Pages/profile/profile";
import Pannel from "./Pages/Pannel/Pannel";
import NotFoundPage from "./Pages/NotFoundPage";
import ViewPage from "./Pages/ViewPage/ViewPage";
import "./main.css";
import icon from "./assets/icon/favicon-32x32.ico"

const App = () => {
  const [stock, setStock] = useState([]);
  const updateStock = (newStock) => {
    setStock(newStock);
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginForm />} />
        <Route path="/" element={<Navigate to="/login" />} />

        <Route element={<RequireAuth />}>
          <Route path="/factor" element={<StorePage />} />
        </Route>
        <Route element={<Layout showHeaderAndToolbar={true} />}>
          <Route path="/home" element={<HomePage />} />
          <Route path="/profile" element={<Profile />} />
          <Route
            path="/stores"
            element={<Pannel updateStock={updateStock} />}
          />
          <Route path="/view/:id" element={<ViewPage stock={stock} />} />
        </Route>
        <Route path="/404" element={<NotFoundPage />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
