import React, { useState, useEffect } from "react";
import circle from "../../../src/assets/img/headercircle.png";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import Table from "../../components/Table/Table";
import "./HomePage.css";
import Cookies from "js-cookie";
import API_URL from "../../components/config";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const navigate = useNavigate();
  const [chartDataArea, setChartDataArea] = useState({
    series: [
      {
        name: "موجودی",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        fontFamily: "ModamFaNumWeb",
      },
      dataLabels: {
        enabled: false,
        offsetY: -25,

        style: {
          fontSize: "10px",
          colors: ["#304758"],
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
          dataLabels: {
            position: "top", // top, center, bottom
          },
          // width: 0.001,
          columnWidth: "25%",
        },
      },

      xaxis: {
        labels: {
          rotate: 360,
          rotateAlways: true,
          offsetY: 0,
          offsetX: 0,
          align: "center",
        },

        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
    },
  });
  const [chartData1, setChartData1] = useState({
    options: {
      chart: {
        id: "sparkline1",
        group: "sparklines",
        type: "area",
        height: 160,
        fontFamily: "ModamFaNumWeb",
        sparkline: {
          enabled: true,
        },
      },
      fill: {
        opacity: 1,
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        min: 0,
      },
      colors: ["#00966D"],
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `
            <div class="arrow_box_main_s">
              <div class="arrow_box_s">
                <span>${w.globals.labels[dataPointIndex]}</span><br />
              </div>
              <div class="arrow_box_data_s">
                <span style="color: green"> ورودی: ${series[seriesIndex][dataPointIndex]}</span>
                <span style="color: red"> خروجی: ${series[seriesIndex][dataPointIndex]}</span>
                <span> موجودی: ${series[seriesIndex][dataPointIndex]}</span>
              </div>
            </div>
          `;
        },
      },
    },
    series: [
      {
        name: "آماده فروش",
        data: [],
      },
    ],
  });

  const [chartData2, setChartData2] = useState({
    options: {
      chart: {
        id: "sparkline2",
        group: "sparklines",
        type: "area",
        height: 160,
        fontFamily: "ModamFaNumWeb",
        sparkline: {
          enabled: true,
        },
      },
      fill: {
        opacity: 1,
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        min: 0,
      },
      colors: ["#000000"],
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `
            <div class="arrow_box_main_s">
              <div class="arrow_box_s">
                <span>${w.globals.labels[dataPointIndex]}</span><br />
              </div>
              <div class="arrow_box_data_s">
                <span style="color: green"> ورودی: ${series[seriesIndex][dataPointIndex]}</span>
                <span style="color: red"> خروجی: ${series[seriesIndex][dataPointIndex]}</span>
                <span> موجودی: ${series[seriesIndex][dataPointIndex]}</span>
              </div>
            </div>
          `;
        },
      },
    },
    series: [
      {
        name: "کنترل کیفیت",
        data: [],
      },
    ],
  });

  const [chartData3, setChartData3] = useState({
    options: {
      chart: {
        id: "sparkline3",
        group: "sparklines",
        type: "area",
        height: 160,
        fontFamily: "ModamFaNumWeb",
        sparkline: {
          enabled: true,
        },
      },
      fill: {
        opacity: 1,
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        min: 0,
      },
      colors: ["#003488"],
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `
            <div class="arrow_box_main_s">
              <div class="arrow_box_s">
                <span>${w.globals.labels[dataPointIndex]}</span><br />
              </div>
              <div class="arrow_box_data_s">
                <span style="color: green"> ورودی: ${series[seriesIndex][dataPointIndex]}</span>
                <span style="color: red"> خروجی: ${series[seriesIndex][dataPointIndex]}</span>
                <span> موجودی: ${series[seriesIndex][dataPointIndex]}</span>
              </div>
            </div>
          `;
        },
      },
    },
    series: [
      {
        name: "در حال تولید",
        data: [],
      },
    ],
  });
  //////////////////////

  const csrfToken = Cookies.get("csrfToken");
  const username = localStorage.getItem("user").replace(/"/g, "");
  const [apiCalled, setApiCalled] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [mojodiYear, setMojodiYear] = useState("");
  const [mojodiLastYear, setMojodiLastYear] = useState("");
  const [dataSelect, setDataSelect] = useState([]);
  const [handleChartBar, setHandleChartBar] = useState({});
  const [data, setData] = useState(null);
  const [lastData1, setLastData1] = useState(null);
  const [lastData2, setLastData2] = useState(null);
  const [lastData3, setLastData3] = useState(null);

  //////////////////////

  const Logout = () => {
    const tokenString = localStorage.getItem("token");
    const tokenObject = JSON.parse(tokenString);
    const accessToken = tokenObject.access;
    const refreshToken = tokenObject.refresh;
    const formData = new URLSearchParams();
    formData.append("refresh_token", refreshToken);
    fetch(`${API_URL}/accounts/logout/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRFToken": csrfToken,
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData.toString(),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("logout");
        localStorage.removeItem("token");
        // localStorage.removeItem("access");
        // window.location.replace('http://127.0.0.1:8000/login');
        navigate("/login");
      });
  };
  useEffect(() => {
    const handleInvalidToken = async () => {
      const tokenString = localStorage.getItem("token");
      try {
        const tokenObject = JSON.parse(tokenString);
        const refreshToken = tokenObject.refresh;
        const formData = new URLSearchParams();
        formData.append("refresh", refreshToken);
        const response = await fetch(`${API_URL}/accounts/token/refresh/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-CSRFToken": csrfToken,
            // Add any other headers if needed
          },
          body: formData.toString(),
        });

        if (response.ok) {
          const data = await response.json();
          tokenObject.access = data.access;
          localStorage.setItem("token", JSON.stringify(tokenObject));
          console.log("Another API data:", data.access);
          fetchData();
        } else {
          console.error("Another API request failed", response.statusText);
          await Logout();
        }
      } catch (error) {
        console.error("An error occurred while calling another API", error);
      }
    };
    const fetchData = async () => {
      const tokenString = localStorage.getItem("token");
      try {
        const tokenObject = JSON.parse(tokenString);
        const accessToken = tokenObject.access;
        const response = await fetch(`${API_URL}/pannel/home/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
            Authorization: `Bearer ${accessToken}`, // اگر نیاز به توکن دسترسی دارید
          },
          body: JSON.stringify({
            // username: localStorage.getItem("user"),
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          if (response.status === 404) {
            console.log(errorData.message);
          } else if (response.status === 401) {
            await handleInvalidToken();
          } else if (response.status === 403) {
            Logout();
          } else {
            console.error("Submission failed", response.statusText);
          }
          return;
        }

        const data = await response.json();
        ///
        setData(data.chart?.data || null);
        console.log(data.chart.data);

        ///
        setInventoryData(data.Table || []);
        const keys = Object.keys(data.chartBar);
        setHandleChartBar(data.chartBar);
        const formattedArray = keys.map((key) => {
          return { value: key, label: key };
        });

        setDataSelect(formattedArray);

        const { date: tarikh, data: chartData } = data.chart;
        const {
          anbar: {
            inventory: sparklineData1,
            in: sparklineData1Vrodi,
            out: sparklineData1khoroji,
          },
          qc: {
            inventory: sparklineData2,
            in: sparklineData2Vrodi,
            out: sparklineData2khoroji,
          },
          tolid: {
            inventory: sparklineData3,
            in: sparklineData3Vrodi,
            out: sparklineData3khoroji,
          },
        } = chartData;
        const lastData1 = sparklineData1[sparklineData1.length - 1];
        const lastData2 = sparklineData2[sparklineData2.length - 1];
        const lastData3 = sparklineData3[sparklineData3.length - 1];
        console.log(lastData1);
        setLastData1(lastData1);
        setLastData2(lastData2);
        setLastData3(lastData3);

        setChartData1((prev) => ({
          ...prev,
          options: {
            ...prev.options,
            xaxis: {
              ...prev.options.xaxis,
              categories: tarikh,
            },
            tooltip: {
              ...prev.options.tooltip,
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return `
                  <div class="arrow_box_main_s">
                    <div class="arrow_box_s">
                      <span>${tarikh[dataPointIndex]}</span><br />
                    </div>
                    <div class="arrow_box_data_s">
                      <span style="color: green"> ورودی: ${sparklineData1Vrodi[dataPointIndex]}</span>
                      <span style="color: red"> خروجی: ${sparklineData1khoroji[dataPointIndex]}</span>
                      <span> موجودی: ${series[seriesIndex][dataPointIndex]}</span>
                    </div>
                  </div>
                `;
              },
            },
          },
          series: [
            {
              name: "آماده فروش",
              data: sparklineData1,
            },
          ],
        }));

        setChartData2((prev) => ({
          ...prev,
          options: {
            ...prev.options,
            xaxis: {
              ...prev.options.xaxis,
              categories: tarikh,
            },
            tooltip: {
              ...prev.options.tooltip,
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return `
                  <div class="arrow_box_main_s">
                    <div class="arrow_box_s">
                      <span>${tarikh[dataPointIndex]}</span><br />
                    </div>
                    <div class="arrow_box_data_s">
                      <span style="color: green"> ورودی: ${sparklineData2Vrodi[dataPointIndex]}</span>
                      <span style="color: red"> خروجی: ${sparklineData2khoroji[dataPointIndex]}</span>
                      <span> موجودی: ${series[seriesIndex][dataPointIndex]}</span>
                    </div>
                  </div>
                `;
              },
            },
          },
          series: [
            {
              name: "کنترل کیفیت",
              data: sparklineData2,
            },
          ],
        }));

        setChartData3((prev) => ({
          ...prev,
          options: {
            ...prev.options,
            xaxis: {
              ...prev.options.xaxis,
              categories: tarikh,
            },
            tooltip: {
              ...prev.options.tooltip,
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return `
                  <div class="arrow_box_main_s">
                    <div class="arrow_box_s">
                      <span>${tarikh[dataPointIndex]}</span><br />
                    </div>
                    <div class="arrow_box_data_s">
                      <span style="color: green"> ورودی: ${sparklineData3Vrodi[dataPointIndex]}</span>
                      <span style="color: red"> خروجی: ${sparklineData3khoroji[dataPointIndex]}</span>
                      <span> موجودی: ${series[seriesIndex][dataPointIndex]}</span>
                    </div>
                  </div>
                `;
              },
            },
          },
          series: [
            {
              name: "در حال تولید",
              data: sparklineData3,
            },
          ],
        }));
        setApiCalled(true);
      } catch (error) {
        console.error("An error occurred", error);
      }
    };

    fetchData();
  }, []);

  //////////////////////

  const renderSection = (sectionData) => {
    if (!sectionData) return null;

    const { number, isAscending } = sectionData;

    const textColor = isAscending ? "green" : "red";
    const backGround = isAscending ? "#F3FDFA" : "#FFF2F2";
    const iconArrow = isAscending ? "bi bi-arrow-up" : "bi bi-arrow-down";

    return (
      <p
        style={{
          color: textColor,
          background: backGround,
        }}
        className={`yourClassName ${iconArrow} caption-md`}>
        {number}%
      </p>
    );
  };

  const handleWarehouseChange = (selectedOption) => {
    setSelectedWarehouse(selectedOption);
    console.log(selectedOption.value);
    const extraction = handleChartBar[selectedOption.value];
    setMojodiYear(handleChartBar[selectedOption.value].mojodiYear);
    setMojodiLastYear(handleChartBar[selectedOption.value].lastYear);
    const loading1 =
      (handleChartBar[selectedOption.value].mojodiYear /
        handleChartBar[selectedOption.value].mojodi) *
      100;
    const loading2 =
      (handleChartBar[selectedOption.value].lastYear /
        handleChartBar[selectedOption.value].mojodi) *
      100;
    document.getElementById("loading-info-1").style.width = `${loading1}%`;
    document.getElementById("loading-info-2").style.width = `${loading2}%`;
    setChartDataArea((prev) => ({
      ...prev,

      series: [
        {
          name: "موجودی",
          data: handleChartBar[selectedOption.value].chart,
        },
      ],
    }));

    console.log(extraction);
  };

  return (
    <div className="homepage">
      <div className="welcome-sec">
        <div className="welcome-info">
          <p className="Body-MD">خوش برگشتید</p>
          <h4> آقای {username}</h4>
          <p className="welcome-p2">
            جهت بهبود تجربه کاربری می توانید سوالات خود را از پشتیبانی بپرسید
          </p>
        </div>
        <img className="circle-head" src={circle} />
      </div>
      {apiCalled ? (
        <div className="chart-over">
          <div className="chart-bar-over">
            <h5>محصولات آماده فروش</h5>
            <div className="chart-sec">
              <ReactApexChart
                options={chartData1.options}
                series={chartData1.series}
                type="area"
                height={200}
              />
              <div className="totalchart">
                <p>- تعداد : {lastData1} عدد </p>
              </div>
            </div>
            <div className="sec-info-chart">
              <div className="icon-one">
                <i class="bi bi-clipboard-data icon-filter"></i>
              </div>
              <div className="info-sec">
                {renderSection(data.anbar)}

                <p className="welcome-p2"> موجودی نسبت به هفته گذشته </p>
              </div>
            </div>
          </div>
          <div className="chart-bar-over">
            <h5>محصولات کنترل کیفیت</h5>
            <div className="chart-sec">
              <ReactApexChart
                options={chartData2.options}
                series={chartData2.series}
                type="area"
                height={200}
              />
              <div className="totalchart">
                <p>- تعداد : {lastData2} عدد </p>
              </div>
            </div>
            <div className="sec-info-chart">
              <div className="icon-one">
                <i class="bi bi-clipboard-data icon-filter"></i>
              </div>
              <div className="info-sec">
                {renderSection(data.qc)}

                <p className="welcome-p2"> موجودی نسبت به هفته گذشته</p>
              </div>
            </div>
          </div>
          <div className="chart-bar-over">
            <h5>محصولات در حال تولید</h5>
            <div className="chart-sec">
              <ReactApexChart
                options={chartData3.options}
                series={chartData3.series}
                type="area"
                height={200}
              />
              <div className="totalchart">
                <p>- تعداد : {lastData3} عدد </p>
              </div>
            </div>
            <div className="sec-info-chart">
              <div className="icon-one">
                <i class="bi bi-clipboard-data icon-filter"></i>
              </div>
              <div className="info-sec">
                {renderSection(data.tolid)}

                <p className="welcome-p2"> موجودی نسبت به هفته گذشته</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="chart-over">
          <div className="chart-bar-over">
            <div className="loading-home">
              <div id="loading" style={{ display: "contents" }}>
                <div className="wrapper" style={{ position: "relative" }}>
                  <div className="circle"></div>
                  <div className="circle"></div>
                  <div className="circle"></div>
                  <div className="shadow"></div>
                  <div className="shadow"></div>
                  <div className="shadow"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="chart-bar-over">
            <div className="loading-home">
              <div id="loading" style={{ display: "contents" }}>
                <div className="wrapper" style={{ position: "relative" }}>
                  <div className="circle"></div>
                  <div className="circle"></div>
                  <div className="circle"></div>
                  <div className="shadow"></div>
                  <div className="shadow"></div>
                  <div className="shadow"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="chart-bar-over">
            <div className="loading-home">
              <div id="loading" style={{ display: "contents" }}>
                <div className="wrapper" style={{ position: "relative" }}>
                  <div className="circle"></div>
                  <div className="circle"></div>
                  <div className="circle"></div>
                  <div className="shadow"></div>
                  <div className="shadow"></div>
                  <div className="shadow"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="table-sec">
        {dataSelect.length !== 0 ? (
          <div className="chart-bar-full">
            <div className="sec-chart-area">
              <ReactApexChart
                options={chartDataArea.options}
                series={chartDataArea.series}
                type="bar"
                height={280}
              />
            </div>
            <div className="sec-info-area">
              <div className="drop-store">
                <Select
                  value={selectedWarehouse}
                  onChange={handleWarehouseChange}
                  options={dataSelect}
                  placeholder="یک انبار را انتخاب کنید"
                />
              </div>
              <div className="sec-detail">
                <div className="sec-detail-1">
                  <div className="info-data">
                    <h6>امسال</h6>
                    <div className="bi bi-card-checklist icon-det"></div>
                  </div>
                  <h5>{mojodiYear}</h5>
                  <div className="loading">
                    <div id="loading-info-1"></div>
                  </div>
                </div>

                <div className="sec-detail-2">
                  <div className="info-data">
                    <h6>سال های قبل</h6>
                    <div className="bi bi-list-check icon-det"></div>
                  </div>
                  <h5>{mojodiLastYear}</h5>
                  <div className="loading">
                    <div id="loading-info-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="chart-bar-full">
            <div id="loading" style={{ display: "contents" }}>
              <div
                className="wrapper"
                style={{ position: "relative", right: "15%" }}>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
              </div>
            </div>
          </div>
        )}

        <Table data={inventoryData} />
      </div>
    </div>
  );
}

export default HomePage;
