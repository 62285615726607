import React, { useState, useEffect, useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Header } from "../components/Header/Header";
import { Menu } from "../components/Menu/Menu";
import { Footer } from "../components/Footer/Footer";
import "./Layout.css";
export function Layout({ showHeaderAndToolbar }) {
  const token = localStorage.getItem("token");
  if (!token || JSON.parse(localStorage.getItem("pannel")) === "4") {
    return <Navigate to={{ pathname: "/login" }} replace />;
    // return <Outlet />;
  } else {
    return (
      <div className="app">
        <div className="menu-outlet">
          {showHeaderAndToolbar && <Menu />}
          <div className="outlet">
            {showHeaderAndToolbar && <Header />}
            <Outlet />
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
