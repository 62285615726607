import React from "react";
import { HouseFill, PersonFill, BellFill } from "react-bootstrap-icons";
import "./Header.css";
import { useLocation } from "react-router-dom";

export const Header = () => {
  const location = useLocation();
  const { pathname } = location;

  const pageNames = {
    home: "خانه",
    profile: "پروفایل",
    stores: "انبارها",
    view: "مشاهده",
  };

  const getPageName = (pathname) => {
    if (pathname.startsWith("/view")) {
      const id = pathname.split("/")[2];
      return `مشاهده /${id}`;
    }
    return pageNames[pathname.replace("/", "")] || "صفحه مورد نظر یافت نشد";
  };

  const header = getPageName(pathname);
  const title = getPageName(pathname);

  return (
    <div className="header">
      <div className="first">
        <HouseFill className="home-icon" color="#63BAFD" />
        <span className="text-root Body-LG" style={{ fontSize: "14px" }}>
          {title}
        </span>
        <p className="text-header Body-LG">{header}</p>
      </div>
      <div className="profile-div">
        <div className="info">
          <div className="profile">
            <PersonFill className="profile-icon" />
          </div>
          <div className="des">
            <p className="profile-title caption-lg">
              {localStorage.getItem("user").replace(/"/g, "")}
            </p>
            <p className="profile-description">مدیر</p>
          </div>
        </div>
        <div className="notification">
          <BellFill className="notification-icon" />
        </div>
      </div>
    </div>
  );
};
