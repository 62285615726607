import CardStore from "../../components/Card/CardStore";
import pdf from "../../../src/assets/img/docx.png";
import exel from "../../../src/assets/img/excel.png";
import ReactApexChart from "react-apexcharts";
import React, { useState, useEffect } from "react";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import plus from "../../assets/img/resource-database-3PDEp-zpcSI-unsplash 2.png";
import axios from "axios";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "./Pannel.css";
import Cookies from "js-cookie";
import API_URL from "../../components/config";
import { useNavigate } from "react-router-dom";
import {
  Document,
  Packer,
  Paragraph,
  Table,
  TableRow,
  TableCell,
  TextRun,
  HeadingLevel,
  WidthType,
} from "docx";

function Pannel({ updateStock }) {
  const navigate = useNavigate();
  const csrfToken = Cookies.get("csrfToken");
  const [stock, setStock] = useState(null);
  const [apiCalled, setApiCalled] = useState(false);
  const [totalGraph, setTotalGraph] = useState(0);
  const [chartData, setChartData] = useState({
    series: [
      { data: [], name: "کل محصولات" },
      { data: [], name: "نوع محصولات" },
    ],
    options: {
      chart: {
        height: 280,
        type: "area",
        fontFamily: "ModamFaNumWeb",
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#32A4FC", "#f5882f"],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
      xaxis: {
        categories: [],
      },
    },
  });
  const [chartDataGraph, setChartDataGraph] = useState({
    series: [],
    chartOptions: {
      dataLabels: {
        style: {
          fontSize: "11px",
          // fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: "none",
          // colors: ["#000"],
        },
      },
      colors: ["#63BAFD", "#292D30", "#FA7100", "#cbcbcb"],
      chart: {
        height: 300,
        type: "donut",
        fontFamily: "ModamFaNumWeb",
      },
      labels: [],
      plotOptions: {
        pie: {
          donut: {
            size: "65%",
            background: "#eff0f1",
            labels: {
              show: true,
              total: {
                show: true,
                label: "موجودی",
                color: "#000",
              },
            },
          },
        },
      },
    },
  });

  const Logout = () => {
    const tokenString = localStorage.getItem("token");
    const tokenObject = JSON.parse(tokenString);
    const accessToken = tokenObject.access;
    const refreshToken = tokenObject.refresh;
    const formData = new URLSearchParams();
    formData.append("refresh_token", refreshToken);
    fetch(`${API_URL}/accounts/logout/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRFToken": csrfToken,
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData.toString(),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("logout");
        localStorage.removeItem("token");
        // localStorage.removeItem("access");
        // window.location.replace('http://127.0.0.1:8000/login');
        navigate("/login");
      });
  };

  useEffect(() => {
    const handleInvalidToken = async () => {
      const tokenString = localStorage.getItem("token");
      try {
        const tokenObject = JSON.parse(tokenString);
        const refreshToken = tokenObject.refresh;
        const formData = new URLSearchParams();
        formData.append("refresh", refreshToken);
        const response = await fetch(`${API_URL}/accounts/token/refresh/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-CSRFToken": csrfToken,
            // Add any other headers if needed
          },
          body: formData.toString(),
        });

        if (response.ok) {
          const data = await response.json();
          tokenObject.access = data.access;
          localStorage.setItem("token", JSON.stringify(tokenObject));
          console.log("Another API data:", data.access);
          fetchData();
        } else {
          console.error("Another API request failed", response.statusText);
          await Logout();
        }
      } catch (error) {
        console.error("An error occurred while calling another API", error);
      }
    };
    const fetchData = async () => {
      const tokenString = localStorage.getItem("token");
      try {
        const tokenObject = JSON.parse(tokenString);
        const accessToken = tokenObject.access;
        const response = await fetch(`${API_URL}/pannel/pannel/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            // username: localStorage.getItem("user"),
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          if (response.status === 404) {
            console.log(errorData.message);
          } else if (response.status === 401) {
            await handleInvalidToken();
          } else if (response.status === 403) {
            Logout();
          } else {
            console.error("Submission failed", response.statusText);
          }
          return;
        }

        const data = await response.json();

        console.log(data.graph);
        setStock(data.cart);
        updateStock(data.cart);
        //
        const categories = data.chart.DateW;
        const grade1 = data.chart.grade_1;
        const grade2 = data.chart.grade_2;

        setChartData((prevChartData) => ({
          ...prevChartData,
          series: [
            { data: grade1, name: "درجه یک" },
            { data: grade2, name: "درجه دو" },
          ],
          options: {
            ...prevChartData.options,
            xaxis: {
              ...prevChartData.options.xaxis,
              categories: categories,
            },
          },
        }));
        //
        const series = data.graph.data;
        const labels = data.graph.name;
        const totalStock = series.reduce((acc, curr) => acc + curr, 0);
        setTotalGraph(totalStock);
        setChartDataGraph((prevChartDataGraph) => ({
          ...prevChartDataGraph,
          series: series,
          chartOptions: { ...prevChartDataGraph.chartOptions, labels: labels },
        }));

        //
        setApiCalled(true);
      } catch (error) {
        console.error("An error occurred", error);
      }
    };

    fetchData();
  }, []);

  //
  const handleInvalidToken2 = async (fileType) => {
    console.log("saeed");
    const tokenString = localStorage.getItem("token");
    try {
      const tokenObject = JSON.parse(tokenString);
      const refreshToken = tokenObject.refresh;
      const formData = new URLSearchParams();
      formData.append("refresh", refreshToken);
      const response = await fetch(`${API_URL}/accounts/token/refresh/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRFToken": csrfToken,
          // Add any other headers if needed
        },
        body: formData.toString(),
      });

      if (response.ok) {
        const data = await response.json();
        tokenObject.access = data.access;
        localStorage.setItem("token", JSON.stringify(tokenObject));
        console.log("Another API data:", data.access);
        fetchDataAndDownload(fileType);
      } else {
        console.error("Another API request failed", response.statusText);
        await Logout();
      }
    } catch (error) {
      console.error("An error occurred while calling another API", error);
    }
  };
  const fetchDataAndDownload = async (fileType) => {
    const tokenString = localStorage.getItem("token");
    const tokenObject = JSON.parse(tokenString);
    const accessToken = tokenObject.access;
    const apiUrl = `${API_URL}/pannel/report/`;
    // const csrfToken = "YOUR_CSRF_TOKEN";
    // const username = localStorage.getItem("user");

    try {
      const response = await axios.post(
        apiUrl,
        {
          // username: username,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = response.data.report;

      if (fileType === "excel") {
        downloadExcel(data);
      } else if (fileType === "pdf") {
        downloadWord(data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await handleInvalidToken2(fileType);
      } else if (error.response && error.response.status === 403) {
        Logout();
      } else {
        console.error("Error fetching data:", error);
      }
    }
  };

  const downloadExcel = (data) => {
    const workbook = XLSX.utils.book_new();

    for (const [sheetName, sheetData] of Object.entries(data)) {
      const worksheet = XLSX.utils.json_to_sheet(sheetData);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    }

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "report.xlsx");
  };

  const downloadWord = (data) => {
    const doc = new Document({
      sections: Object.entries(data).map(([sheetName, sheetData]) => ({
        properties: {},
        children: [
          new Paragraph({
            text: sheetName,
            heading: HeadingLevel.HEADING_1,
            spacing: {
              after: 200,
            },
          }),
          new Table({
            rows: [
              new TableRow({
                children: Object.keys(sheetData[0]).map(
                  (key) =>
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [new TextRun({ text: key, bold: true })],
                        }),
                      ],
                      width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                      },
                    })
                ),
              }),
              ...sheetData.map(
                (item) =>
                  new TableRow({
                    children: Object.values(item).map(
                      (value) =>
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [new TextRun({ text: value })],
                            }),
                          ],
                          width: {
                            size: 100,
                            type: WidthType.PERCENTAGE,
                          },
                        })
                    ),
                  })
              ),
            ],
          }),
        ],
      })),
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "report.docx");
    });
  };

  //
  const scrollRight = () => {
    const container = document.querySelector(".cards-container");
    if (container) {
      container.scrollLeft += 100;
    }
  };
  const scrollLeft = () => {
    document.getElementById("scrolButton").style.display = "inline-flex";
    var arrows = document.getElementsByClassName("arrow-scroll");
    for (var i = 0; i < arrows.length; i++) {
      arrows[i].style.justifyContent = "space-between";
    }

    const container = document.querySelector(".cards-container");
    if (container) {
      container.scrollLeft -= 100;
    }
  };
  //

  return (
    <div className="container">
      <div className="first-pannel">
        <div className="head-store">
          <p
            style={{ fontSize: "24px", fontWeight: "600", lineHeight: "140%" }}>
            انبار ها
          </p>
          <button className="now-store Button-SM">در حال حاظر</button>
        </div>
        <div className="curved">
          <div className="curved-corner-topright"></div>
          <div className="curved-corner-topleft"></div>
        </div>
      </div>
      <div className="second">
        <div className="curved2">
          <div className="curved-corner-bottomright"></div>
          <div className="curved-corner-bottomleft"></div>
        </div>
        <div className="data-second">
          <div className="info-second">
            <p className="Body-MD">
              - در این بخش لیستی از انبار های تعریف شده خط تولید شما می باشد
            </p>
            <p className="Body-MD">
              - جهت مشاهده جزئیات بر روی دکمه ( مشاهده ) کلیک کنید{" "}
            </p>
          </div>
          <div className="arrow-scroll">
            <button
              onClick={scrollRight}
              id="scrolButton"
              className="scroll-button"
              style={{ display: "none" }}>
              <BsChevronRight />
            </button>
            <button onClick={scrollLeft} className="scroll-button">
              <BsChevronLeft />
            </button>
          </div>
          <div className={`cards-container  scrolling`}>
            <div className="cards">
              {stock ? (
                stock.map((item, index) => (
                  <CardStore
                    id={item.id}
                    name={item.name}
                    data={item.data}
                    mojodi={item.mojodi}
                  />
                ))
              ) : (
                <div id="loading" style={{ display: "contents" }}>
                  <div
                    className="wrapper"
                    style={{ position: "relative", left: "35%" }}>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="shadow"></div>
                    <div className="shadow"></div>
                    <div className="shadow"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="third">
        <div className="sec-full">
          <div className="sec-plus">
            <img className="custom-img-plus" src={plus} />
            <samp style={{ color: "red", display: "flex" }}>
              "
              <h4 className="caption-md" style={{ color: "#000" }}>
                افزودن انبار جدید
              </h4>
              "
            </samp>
            <i class="bi bi-chevron-down icon-plus"></i>
            {/* <p>پشتیبانی</p> */}
            <a
              href="tel:09215282680"
              style={{ textDecoration: "none", color: "#014274" }}
              className="Button-SM">
              پشتیبانی
            </a>
          </div>
          <div className="report-file">
            <div
              className="exel-file"
              onClick={() => fetchDataAndDownload("excel")}>
              <img className="custom-icon-exel" src={exel} />
              <span className="textBtn caption-md">دریافت فایل Excel</span>
            </div>
            <div
              className="pdf-file"
              onClick={() => fetchDataAndDownload("pdf")}>
              <img className="custom-icon-pdf" src={pdf} />
              <span className="textBtn caption-md">دریافت فایل Word</span>
            </div>
          </div>
        </div>

        {apiCalled ? (
          <div className="graph">
            <div className="graph-info">
              <h6>نمودار موجودی انبار ها</h6>
              <p className="caption-md" style={{ color: "#757575" }}>
                مجموع موجودی انبار ها
              </p>
              <h6> عدد : {totalGraph}</h6>
            </div>
            <div className="graph-sec">
              <ReactApexChart
                options={chartDataGraph.chartOptions}
                series={chartDataGraph.series}
                type="donut"
                height={300}
              />
            </div>
          </div>
        ) : (
          <div className="graph">
            <div id="loading" style={{ display: "contents" }}>
              <div
                className="wrapper"
                style={{ position: "relative", left: "29%" }}>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
              </div>
            </div>
          </div>
        )}
        {apiCalled ? (
          <div className="chrat-bar">
            <div className="chart-info">
              <h6>نمودار درجه بندی محصولات</h6>
            </div>
            <div className="chart-sec">
              <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="area"
                height={280}
              />
            </div>
          </div>
        ) : (
          <div className="chrat-bar">
            <div id="loading" style={{ display: "contents" }}>
              <div
                className="wrapper"
                style={{ position: "relative", right: "9%", top: "9%" }}>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Pannel;
