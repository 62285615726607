import {Navigate, Outlet} from 'react-router-dom'
import StorePage from "../StorePage/StorePage";

export function RequireAuth() {
    const token = localStorage.getItem("token")
    if(!token)
    {
        return <Navigate to={{pathname: "/login"}} replace/>
    }
    else{

        return <Outlet />
    }

}