import React, { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Logo from "../assets/img/layer-1-1-1.png";
import poshtiban from "../assets/icon/Customer Support.svg";
import * as Yup from "yup";
import "./style.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import about from "../assets/icon/profile-2user.svg";
import tel_me from "../assets/icon/call-add.svg";
import factor from "../assets/icon/chart.svg";
import icon_login from "../assets/icon/Asset 2 2.svg";
import API_URL from "../components/config.js";
const validationSchema = Yup.object({
  username: Yup.string()
    .required("نام کاربری را وارد کنید")
    .matches(/^.{6,10}$/, "نام کاربری باید بین 6 تا 10 کاراکتر باشد")
    .nullable(),
  password: Yup.string().required("رمز عبور را وارد کنید"),
});

function LoginForm() {
  const [value, setValue] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  useEffect(() => {
    console.log("عرض صفحه:", screenWidth);
  }, [screenWidth]);

  // -------------------------------------------------------------------

  const {
    register,
    handleSubmit,
    setError,
    setvalue,
    formState: { errors, touchedFields, isValid },
    triggerValidation,
  } = useForm({
    mode: "onChange",
  });
  const navigate = useNavigate();
  const onFormSubmit = async (data) => {
    try {
      const formData = new URLSearchParams();
      formData.append("username", data.username);
      formData.append("password", data.password);
      const csrfToken = Cookies.get("csrfToken");
      try {
        // درخواست اول
        const response = await axios.post(
          `${API_URL}/accounts/login/`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "X-CSRFToken": csrfToken,
            },
          }
        );

        // اگر درخواست اول موفق بود
        if (response.status === 200) {
          console.log("login response is ok");
          console.log("Backend response login:", response.data);
          console.log(response.data.message);
          console.log("data", data);
          console.log("token response is ok");
          console.log("Backend response token:", response.data);
          console.log(response.data.refresh);
          console.log(response.data.access);
          const token = localStorage.getItem("token");
          console.log(`Refresh Value: ${token}`);
          localStorage.setItem("token", JSON.stringify(response.data));
          localStorage.setItem("user", JSON.stringify(data.username));
          const token2 = localStorage.getItem("token");
          console.log(`Refresh Value: ${token2}`);
          localStorage.setItem(
            "pannel",
            JSON.stringify(response.data.user.pannel)
          );
          localStorage.setItem(
            "reader",
            JSON.stringify(response.data.user.idR)
          );
          
          // window.location.replace("http://127.0.0.1:8000/factor");
          if (response.data.user.pannel === "4") {
            navigate("/factor");
          } else if (response.data.user.pannel === "3") {
            navigate("/home");
          }
        } else {
          console.error("login response failed");
          setPasswordErrorMessage(
            "رمز عبور یا نام کاربری را اشتباه وارد کرده اید"
          ); // اضافه شده
        }
      } catch (error) {
        console.error("An error occurred during login:", error);
        setErrorMessage("خطایی رخ داده است");
      }
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    } catch (error) {
      console.error("Error occurred during submission", error);
      setErrorMessage("خطا در ارسال فرم"); // اضافه شده
    }
  };
  const handlePasswordBlur = async () => {
    const isInvalidPassword = true; // اینجا باید چک شود
    if (isInvalidPassword) {
      setError("password", {
        type: "manual",
        message: "رمز ورود اشتباه است",
      });
    } else {
      setValue("password", "");
    }
  };
  const handleClick = () => {
    navigate("/factor");
  };
  const toggleMenu2 = () => {
    setIsOpen(!isOpen);
  };

  // --------------------------------------------------
  return (
    <div className="loginForm">
      <header className="loginPageHeader">
        {(toggleMenu || screenWidth < 768) && (
          <nav className="loginNav">
            <button onClick={toggleNav} className="loginPageBtn">
              <RxHamburgerMenu />
            </button>
            <div id="menuToggle" onClick={toggleMenu2}>
              <input type="checkbox" checked={isOpen} onChange={() => {}} />
              <span></span>
              <span></span>
              <span></span>
              <ul
                id="menu"
                style={{ transform: isOpen ? "none" : "translate(125%, 0)" }}>
                <li className="loginItems">
                  <a href="#">
                    <img src={about} alt="" />
                    &nbsp; درباره ما
                  </a>
                </li>
                <li className="loginItems">
                  <a href="#">
                    <img src={tel_me} alt="" />
                    &nbsp; تماس با ما
                  </a>
                </li>
                <li className="loginItems">
                  <a onClick={handleClick}>
                    <img src={factor} alt="" />
                    &nbsp; نمایش
                  </a>
                </li>
              </ul>
            </div>

            <img className="loginLogo" src={Logo} alt="Logo" />
            <a href="tel:09215282680">
              <button className="headerBtn">
                <img src={poshtiban} alt="" />
              </button>
            </a>
          </nav>
        )}
        {(toggleMenu || screenWidth > 768) && (
          <nav className="loginNav">
            <button onClick={toggleNav} className="loginPageBtn">
              <RxHamburgerMenu />
            </button>
            <a href="tel:09215282680">
              <button className="headerBtn">
                تماس با پشتیبان
                <img src={poshtiban} alt="" />
              </button>
            </a>
            <ul className="loginList">
              <li className="loginItems">
                <a href="#">
                  <img src={about} alt="" />
                  &nbsp; درباره ما
                </a>
              </li>
              <li className="loginItems">
                <a href="#">
                  <img src={tel_me} alt="" />
                  &nbsp; تماس با ما
                </a>
              </li>
              <li className="loginItems">
                <a onClick={handleClick}>
                  <img src={factor} alt="" />
                  &nbsp; نمایش
                </a>
              </li>
            </ul>
            <img className="loginLogo" src={Logo} alt="Logo" />
          </nav>
        )}
      </header>
      <div className="formBody">
        <div className="background-image"></div>
        <div className="glass-login">
          <div className="glass-items">
            <img src={icon_login} alt="" />
            <h3 style={{ marginTop: "15px" }}>ورود به حساب کاربری</h3>
          </div>
          <p
            style={{
              color: "#757575",
              // fontSize: "20px",
              fontFamily: "ModamFaNumWeb",
              paddingTop: "16px",
              paddingBottom: "8px",
            }}>
            برای ورود نام کاربری و رمز خود را وارد کنید
          </p>
          <div className="form-set">
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <section
                className={`form-item ${errors.username ? "error" : ""}`}>
                <div
                  className={`input-wrapper ${
                    touchedFields.username ? "active" : ""
                  } ${errors.username ? "error" : ""}`}>
                  <input
                    {...register("username", {
                      required: "نام کاربری را وارد کنید",
                    })}
                    className={errors.username ? "error" : ""}
                  />
                  <label htmlFor="username" className="placeholder">
                    نام کاربری
                  </label>
                </div>
                {touchedFields.username && errors.username && (
                  <div className="error-message">{errors.username.message}</div>
                )}
              </section>

              <section
                className={`form-item ${errors.password ? "error" : ""}`}>
                <div
                  className={`input-wrapper ${
                    touchedFields.password ? "active" : ""
                  } ${errors.password ? "error" : ""}`}>
                  <input
                    type="password"
                    {...register("password", {
                      required: "رمز ورود را وارد کنید",
                    })}
                    className={errors.password ? "error" : ""}
                  />
                  <label htmlFor="password" className="placeholder">
                    رمز ورود
                  </label>
                </div>
                {touchedFields.password && errors.password && (
                  <div className="error-message">{errors.password.message}</div>
                )}
              </section>

              {passwordErrorMessage && (
                <div className="error-message">{passwordErrorMessage}</div>
              )}

              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}

              <div>
                <button
                  type="submit"
                  style={{ width: "100%", height: "40px", marginTop: "8px" }}
                  className={`loginbutton ${isValid ? "" : "disabled"}`}
                  disabled={!isValid}>
                  ورود
                </button>
              </div>
            </form>
          </div>
          <p className="form-item">
            اگر حساب کاربری ندارید به <a href="https://alohco.ir/">سایت آلُه</a>{" "}
            مراجعه نمایید
          </p>
        </div>
      </div>

      <footer>
        <div className="footer-detail footer-item">
          <div className="About-us">
            <a href="#">تیم آلُه</a>
            <a href="#">درباره ما</a>
            <a href="#">تماس با ما</a>
          </div>
        </div>

        <div className="footer-info footer-item">
          تهیه شده توسط شرکت فنی و مهندسی آلُه
        </div>
      </footer>
    </div>
  );
}

export default LoginForm;
