import React, { useState, useEffect, useRef } from "react";
import "./FirstTab.css";
import ReactApexChart from "react-apexcharts";
const FirstTab = ({ date, inventory, In, out }) => {
  const [chartDataViwe, setChartDataViwe] = useState({
    series: [
      {
        name: "ورودی",
        type: "column",
        data: In,
      },
      {
        name: "خروجی",
        type: "column",
        data: out,
      },
      {
        name: "باقی مانده",
        type: "line",
        data: inventory,
      },
    ],
    options: {
      chart: {
        height: 180,
        type: "line",
        stacked: false,
        fontFamily: "ModamFaNumWeb",
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [2],
      },
      colors: ["#90EE90", "#86C9FD", "#006FEE"],
      stroke: {
        width: [4, 4, 4],
      },
      plotOptions: {
        bar: {
          columnWidth: "30%",
          borderRadius: 2,
        },
      },
      xaxis: {
        categories: date,
      },

      yaxis: [
        {
          seriesName: "Column A",
          labels: {
            rotate: 360,
            rotateAlways: true,
            offsetY: 0,
            offsetX: 0,
            align: "center",
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
        },
      ],

      legend: {
        offsetX: -400,
        align: "right",
        position: "top",
        fontSize: "12px",
        itemMargin: {
          horizontal: 20,
          vertical: 5,
        },
      },
    },
  });
  useEffect(() => {
    setChartDataViwe({
      series: [
        {
          name: "ورودی",
          type: "column",
          data: In,
        },
        {
          name: "خروجی",
          type: "column",
          data: out,
        },
        {
          name: "باقی مانده",
          type: "line",
          data: inventory,
        },
      ],
      options: {
        ...chartDataViwe.options,
        xaxis: {
          categories: date,
        },
      },
    });
  }, [date, inventory, In, out]);
  return (
    <div className="main-detailSection-p-f">
      <ReactApexChart
        options={chartDataViwe.options}
        series={chartDataViwe.series}
        type="line"
        height={180}
      />
    </div>
  );
};
export default FirstTab;
