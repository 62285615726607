import React from "react";
import "./Footer.css";
import { RiHome5Fill } from "react-icons/ri";
import { TbCreditCard } from "react-icons/tb";
import { MdEditSquare } from "react-icons/md";
import { RiShoppingCartFill } from "react-icons/ri";
import { PiUserDuotone } from "react-icons/pi";
import { BiLogOut } from "react-icons/bi";
import { NavLink } from "react-router-dom";
export function Footer() {
  return (
    <footer className="footer">
      <p className="footer-text">تهیه شده توسط شرکت فنی و مهندسی آلُه</p>
      <ul className="footer-list">
        <li className="footer-list__item">
          <NavLink to="#" className="nav_link-footer" data-link>
            تیم آلُه
          </NavLink>
        </li>
        <li className="footer-list__item">
          <NavLink to="#" className="nav_link-footer" data-link>
            درباره ما
          </NavLink>
        </li>
        <li className="footer-list__item">
          <NavLink to="#" className="nav_link-footer" data-link>
            تماس با ما
          </NavLink>
        </li>
      </ul>
    </footer>
  );
}

// import React from "react";
// import "./style.css";

// export const Footer = ({ className }) => {

// };
