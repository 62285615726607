import React, { useState, useEffect } from "react";
import { RiHome5Fill } from "react-icons/ri";
import { TbCreditCard } from "react-icons/tb";
import { PiUserDuotone } from "react-icons/pi";
import { BiLogOut } from "react-icons/bi";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import logo from "../../assets/img/layer-1-1-1.png";
import API_URL from "../config";
import "./Menu.css";
import useIsMobile from "../useismobile/useIsMobile";

export function Menu() {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const csrfToken = Cookies.get("csrfToken");
  const isMobile = useIsMobile();
  useEffect(() => {
    switch (location.pathname) {
      case "/home":
        setSelectedItem("home");
        break;
      case "/stores":
        setSelectedItem("stores");
        break;
      case "/profile":
        setSelectedItem("profile");
        break;
      case "/login":
        setSelectedItem("logout");
        break;
      default:
        setSelectedItem(null);
    }
  }, [location.pathname]);

  const handleLogout = () => {
    const tokenString = localStorage.getItem("token");
    if (tokenString) {
      const tokenObject = JSON.parse(tokenString);
      const accessToken = tokenObject.access;
      const refreshToken = tokenObject.refresh;

      const formData = new URLSearchParams();
      formData.append("refresh_token", refreshToken);

      fetch(`${API_URL}/accounts/logout/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRFToken": csrfToken,
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData.toString(),
      })
        .then((response) => {
          if (response.ok) {
            localStorage.removeItem("token");
            navigate("/login");
          } else {
            throw new Error("Logout failed");
          }
        })
        .catch((error) => {
          console.error("Error during logout:", error);
          alert("Logout failed. Please try again.");
        });
    } else {
      alert("No token found");
    }
  };

  return (
    <div className="main-layout">
      <nav className="nav">
        <div className="nav__logo">
          <img className="frame-56" src={logo} alt="logo" />
        </div>
        <ul className="nav__items">
          <li
            className={`nav__item ${
              selectedItem === "home" ? "show-label" : ""
            }`}>
            <NavLink to="/home" className="nav__link">
              <span className="icon-container">
                <RiHome5Fill className="nav__icon" />
              </span>
              <span className="nav__item-label">خانه</span>
            </NavLink>
          </li>
          <li
            className={`nav__item ${
              selectedItem === "stores" ? "show-label" : ""
            }`}>
            <NavLink to="/stores" className="nav__link">
              <span className="icon-container">
                <TbCreditCard className="nav__icon" />
              </span>
              <span className="nav__item-label">انبار</span>
            </NavLink>
          </li>
          {isMobile ? (
            <img className="frame-56" src={logo} alt="logo" />
          ) : (
            <li className="nav__item">
            <span className="nav__item-account"> حساب کاربری </span>
          </li>
          )}
          

          <li
            className={`nav__item ${
              selectedItem === "profile" ? "show-label" : ""
            }`}>
            <NavLink to="/profile" className="nav__link">
              <span className="icon-container">
                <PiUserDuotone className="nav__icon" />
              </span>
              <span className="nav__item-label">پروفایل</span>
            </NavLink>
          </li>
          <li
            className={`nav__item ${
              selectedItem === "logout" ? "show-label" : ""
            }`}>
            <NavLink to="/login" className="nav__link" onClick={handleLogout}>
              <span className="icon-container">
                <BiLogOut className="nav__icon" />
              </span>
              <span className="nav__item-label">خروج</span>
            </NavLink>
          </li>
        </ul>
        <div>
          <div className="img-nav__container">
            <div className="frame-8">?</div>
            {/* <img src="/img/milad-fakurian-bdg-kktakig-unsplash-1-1.png" alt="img-nav" /> */}
            <p>به کمک نیاز دارید؟</p>
            <p>برای پاسخ به سایت زیر مراجعه کنید</p>
            <button className="nav-button">
              <a href="#">مشاهده &gt;</a>
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
}
